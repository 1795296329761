import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, orderBy, startAfter, limit } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import '../../assets/scss/custom/pages/page.css'

export interface Order {
    id?: string;
    customerName: string;
    orderDate: string;
    status: string;
    totalAmount: number;
    externalFields: string; // New field for external JSON data
}

export default function Orders() {
    const [orders, setOrders] = useState<Order[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const [lastVisible, setLastVisible] = useState<any>(null);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true); // Add loading state
    const [orderToDelete, setOrderToDelete] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const docId = localStorage.getItem("docId");

                if (!docId) {
                    console.error("Document ID not found in localStorage.");
                    return;
                }

                const ordersCollection = collection(db, `Vendors/${docId}/Orders`);
                let q = query(ordersCollection, orderBy("orderDate"), limit(itemsPerPage));

                if (lastVisible) {
                    q = query(ordersCollection, orderBy("orderDate"), startAfter(lastVisible), limit(itemsPerPage));
                }

                const querySnapshot = await getDocs(q);
                const ordersData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...(doc.data() as Omit<Order, "id">),
                }));

                if (ordersData.length < itemsPerPage) {
                    setHasMore(false);
                }

                setOrders(ordersData);
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            } catch (error) {
                console.error("Error fetching orders: ", error);
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };

        fetchOrders();
    }, [currentPage]);

    const handleDeleteOrder = async () => {
        if (!orderToDelete) return;

        try {
            const docId = localStorage.getItem("docId");

            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            await deleteDoc(doc(db, `Vendors/${docId}/Orders`, orderToDelete));
            setOrders((prevOrders) => prevOrders.filter((order) => order.id !== orderToDelete));
            setOrderToDelete(null);
        } catch (error) {
            console.error("Error deleting order:", error);
        }
    };

    const handleNextPage = () => {
        if (hasMore) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setLastVisible(null); // Reset lastVisible to fetch the first page again
        }
    };

    const filteredOrders = orders.filter(
        (order) =>
            order.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.orderDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.status.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className="main-panel container-fluid">
            <div className="content">
                <div className="container-fluid mt-3 p-3">
                    <h4 className="page-title mb-4">Orders</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-tasks">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <div className="flex justify-between items-center mb-4">
                                        <input
                                            type="text"
                                            placeholder="Search here..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="border border-gray-300 rounded-lg px-4 py-2 w-1/3 focus:outline-none focus:ring focus:border-blue-500"
                                        />
                                    </div>
                                    <div className="d-flex gap-2">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => navigate('/order/add-order')}
                                        >
                                            Add New Order
                                        </button>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive px-5 py-4 table-striped">
                                        {loading ? (
                                            <div className="loading-wave">
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                        ) : (
                                            <table className="table table-bordered table-hover ">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Customer Name</th>
                                                        <th>Order Date</th>
                                                        <th>Status</th>
                                                        <th>Total Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredOrders.map((order, index) => (
                                                        <tr key={order.id}>
                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td>{order.customerName}</td>
                                                            <td>{order.orderDate}</td>
                                                            <td>{order.status}</td>
                                                            <td>{order.totalAmount}</td>
                                                            <td className="td-actions">
                                                                <button
                                                                    className="btn btn-link btn-success"
                                                                    title="Edit Order"
                                                                    onClick={() => navigate(`/order/update-order/${order.id}`)}
                                                                >
                                                                    <i className="fas fa-edit"></i>
                                                                </button>
                                                                <button
                                                                    title="Delete Order"
                                                                    className="btn btn-link btn-danger"
                                                                    onClick={() => setOrderToDelete(order.id!)}
                                                                >
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        <div className="d-flex mt-4 justify-content-end align-items-center gap-2">
                                            <button
                                                disabled={currentPage === 1}
                                                onClick={handlePreviousPage}
                                                className={`btn ${currentPage === 1 ? "btn-secondary disabled" : "btn-primary"}`}
                                            >
                                                Previous
                                            </button>
                                            <span className="mx-2">
                                                Page {currentPage}
                                            </span>
                                            <button
                                                onClick={handleNextPage}
                                                className={`btn ${!hasMore ? "btn-secondary disabled" : "btn-primary"}`}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Confirm Delete Modal */}
            {orderToDelete && (
                <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Delete</h5>
                                <button type="button" className="close" onClick={() => setOrderToDelete(null)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this order?</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setOrderToDelete(null)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={handleDeleteOrder}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
