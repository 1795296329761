import React from 'react'
import "../../assets/scss/custom/pages/_categories.scss";
import { useTranslation } from "react-i18next";

const About = (
    { sellerData}: { sellerData: any }
) => {
  const { t } = useTranslation();
  // console.log(sellerData, " sellerData ")
  return (
    <>
     <div className="category-container">
      <div id="container-categories">
        <div className="category-header">
          <div className="left">
            <div className="logo">
              {" "}
              {sellerData?.logo ? (
                <img src={sellerData?.logo} alt={sellerData?.name} />
              ) : (
                <span>Logo</span>
              )}
            </div>
            <div className="logo-name">
              <h4>{sellerData?.name}</h4>
              <p>{sellerData?.description}</p>
            </div>
          </div>
          <div className="middle">
            {/* <h3>
            {t('about.overview')}
            </h3> */}
            <div className="overview">
              <div className="overview-item">
                <h5>{sellerData?.totalCategories || 0}</h5>
                <p>
                {t('about.categories')}
                </p>
              </div>
              <div className="overview-item">
                <h5>{sellerData?.totalProducts || 0}</h5>
                <p>
                {t('about.products')}
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <h5>
            {t('about.aboutUs')}
            </h5>
            <p>{sellerData?.about}</p>
          </div>
        </div>
        </div>
        </div>
    </>
  )
}

export default About