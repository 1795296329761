import React from "react";
import Navbar from "./Navbar";
import Categories from "./Categories";
import CategoryDetail from "./CategoryDetail";
import { db } from "../../firebaseConfig";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import About from "./About";
import { LanguageProvider } from "./contexts/LanguageContext";
import { SearchProvider } from "./contexts/SearchContext";
import "./i18n";
import Hero from "./Hero";
import { useSearchParams } from "react-router-dom";
import { CartProvider } from "./contexts/CartContext";
import Layout from "./Layout";

const Products = () => {
  const [vendorData, setVendorData] = useState<any>(null);
  // State to store fetched inventory
  const [inventory, setInventory] = useState<any[]>([]);
  const [vendorId, setVendorId] = useState<string>("");
  const [chatId, setChatId] = useState<string>("");
  const [cartId, setCartId] = useState<string>("");
  const [WAPhone, setWAPhone] = useState<string>("");

  const [categories, setCategories] = useState<any | null>(null);
  const [cart, setCart] = useState<any[]>([]);
  const [sellerData, setSellerData] = useState<{
    name: string;
    logo: string;
    description: string;
    about: string;
    totalCategories: number;
    totalProducts: number;
    vat: number;
    id: string;
    WBPhone: string;
    currency: string;
  }>({
    name: "",
    logo: "",
    description: "",
    about: "",
    totalCategories: 0,
    totalProducts: 0,
    vat: 0,
    id: "",
    WBPhone: "",
    currency: "",
  });

  const [loading, setLoading] = useState(true);
  const param = useParams();
  let [searchParams] = useSearchParams();
  const [initialCategory, setInitialCategory] = useState<any>(null);
  const [isSearch, setIsSearch] = useState(false);
  const [verifiedUser, setVerifiedUser] = useState(false);
  const [cartStatus, setCartStatus] = useState<string>("");
  // console.log("params ", param)

  // Fetch data function
  const fetchVendorData = async (id: string) => {
    if(!id) {
      console.error("No storeId provided! ",id);
      return;
    }
    try {
      const vendorsRef = collection(db, "Vendors");

      const q = query(vendorsRef, where("storeId", "==", id));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const fetchedData: any = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))[0]; // Expecting only one vendor
        // console.log("fetched vendor data ", fetchedData);
        // console.log(JSON.stringify(fetchedData, null, 2));
        const vendor = {
          name: fetchedData?.companyName,
          logo: fetchedData?.logo,
          description: fetchedData?.description,
          about: fetchedData?.about,
          totalCategories: fetchedData?.Categories?.length,
          totalProducts: 0,
          vat: Number(fetchedData?.vat),
          id: fetchedData?.id,
          WBPhone: fetchedData?.WBPhone,
          currency: fetchedData?.currency,
        };
        setSellerData(vendor);
        setVendorId(fetchedData?.id);
        // console.log("fetchedData ",fetchedData, "vendor ",vendor)
        // console.log(JSON.stringify(fetchedData))
        // @ts-ignore
        setCategories(fetchedData?.Categories);
      } else {
        console.error("No vendor found with this storeId!");
      }
    } catch (error) {
      console.error("Error fetching vendor data:", error);
    } finally {
      setLoading(false);
    }
  };

  async function fetchVendor(vendorId: string) {
    if (vendorId) {
      try {
        // Fetch vendor data
        const vendorDoc = await getDoc(doc(db, "Vendors", vendorId));
        if (vendorDoc.exists()) {
          console.log("Vendor data:", vendorDoc.data());

          const storeId = vendorDoc.data()?.storeId;
          const fetchedData = vendorDoc.data();
          if (storeId) {
            // fetchVendorData(storeId);
            const vendor = {
              name: fetchedData?.companyName,
              logo: fetchedData?.logo,
              description: fetchedData?.description,
              about: fetchedData?.about,
              totalCategories: fetchedData?.Categories?.length,
              totalProducts: 0,
              vat: Number(fetchedData?.vat),
              id: vendorId,
              WBPhone: fetchedData?.WBPhone,
              currency: fetchedData?.currency,
            };
            setSellerData(vendor);
            console.log("fetchedData ", fetchedData, "vendor ", vendor);
            // console.log(JSON.stringify(fetchedData))
            // @ts-ignore
            setCategories(fetchedData?.Categories);
          }
        } else {
          console.log("No such vendor!");
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      }
    } else {
      console.log("vendorId is undefined or null.");
    }
  }

  const fetchCartDetails = async (cartId: string) => {
    // console.log("fetching cart details for cartId:", cartId);
    if (!cartId) return;
    const id = cartId;
    try {
      const cartsQuery = query(
        collectionGroup(db, "Carts"),
        where("cartId", "==", id)
      );

      const querySnapshot = await getDocs(cartsQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          console.log("Document found:", doc.data(), doc.id);

          // console.log("Document path:", doc.ref.path);
          const vendorId = doc.ref.path.split("/")[1];
          const path = doc.ref.path;
          // console.log("Vendor ID:", vendorId);
          if (vendorId) {
            // fetch vendor data
            fetchVendor(vendorId);
            const { cartId, chatId, newPath } = processPath(path);
            setCartId(cartId);
            setChatId(chatId);
            setVendorId(vendorId);

            // console.log("newPath ", newPath);
            fetchUserCartDetails(path);
            if (newPath) {
              fetchChatDetails(newPath);
            }
          }
        });
      } else {
        console.warn("No documents found for cartId.");
      }
    } catch (error) {
      console.error("Error fetching cart details:", error);
    }
  };

  const fetchChatDetails = async (path: string) => {
    console.log("fetching chat details for path:", path);
    try {
      const docRef = doc(db, path);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Chat Document data:", docSnap.data());
        const data = docSnap.data();
        if (data?.WAPhone) {
          setWAPhone(data.WAPhone);
        }
      } else {
        console.log("No such document found at the specified path.");
      }
    } catch (error) {
      console.error("Error fetching chat document details:", error);
    }
  };

  // Trigger the fetch
  useEffect(() => {
    // fetchVendorData();
    const VendorId = param?.storeId?.toLocaleLowerCase();

    if (VendorId) {
      console.log("storeId ", VendorId);
      fetchVendorData(VendorId);
    }
    const id = param?.cartID;
    if (id) {
      fetchCartDetails(id);
    }
  }, []);

  // Fetch data from the subcollection "Inventory"
  const fetchInventoryData = async (vendorId: string) => {
    if (!vendorId) return;

    try {
      const inventoryRef = collection(db, `Vendors/${vendorId}/Inventory`);
      const querySnapshot = await getDocs(inventoryRef);

      // Map and store fetched data
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log("fetched products ", fetchedData);
      // console.log("fetched products ", fetchedData,JSON.stringify(fetchedData, null, 2));
      if (fetchedData.length > 0) {
        setSellerData((prevData) => ({
          ...prevData,
          totalProducts: fetchedData.length,
        }));
      }

      setInventory(fetchedData);
    } catch (error) {
      console.error("Error fetching inventory:", error);
    } finally {
      setLoading(false);
    }
  };

  // console.log("seller data ", sellerData)

  async function fetchUserCartDetails(path: string) {
    try {
      // Specify the full path to the document
      const docRef = doc(db, path);

      // Fetch the document
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        console.log("Document data:", docSnapshot.data());
        const data = docSnapshot.data(); // Optionally return the document data
        // console.log("Document data:", data);
        setVerifiedUser(true);
        setCartStatus(data?.status || "");
        if (data?.items) {

          setCart(data.items);
        }

        if (data?.products) {
          // console.log("products", data.products);
          setCart(data.products);
        }
      } else {
        console.log("No such document found at the specified path.");
      }
    } catch (error) {
      console.error("Error fetching document details:", error);
    }
  }

  // Trigger data fetch on component mount
  useEffect(() => {
    // console.log("vendorId ", vendorId,"sellerdata",sellerData);
    fetchInventoryData(vendorId);
  }, [vendorId]);

  // console.log("is search ", isSearch);
  // console.log(" cart", cart);

  return (
    <div>
      <CartProvider>
        <LanguageProvider>
          <SearchProvider>
            <Layout
            chtId={chatId}
            carId={cartId}
            vedorId={vendorId}
            cart={cart}
            >
              <Navbar
                cart={cart}
                setCart={setCart}
                sellerData={sellerData}
                cartStatus={cartStatus}
                verifiedUser={verifiedUser}
                WAPhone={WAPhone}
                chatId={chatId}
                cartId={cartId}
                vendorId={vendorId}
                setWAPhone={setWAPhone}
              />
              <Hero
                categories={categories}
                initialCategory={initialCategory}
                inventory={inventory}
                loading={loading}
                sellerData={sellerData}
                setCart={setCart}
                setInitialCategory={setInitialCategory}
                key={initialCategory}
              />
              {/* copyright */}

              <div className="" style={{ backgroundColor: "#f8f9fa" }}>
                <p
                  className="text-center"
                  style={{
                    color: "#000000",
                    fontSize: "12px",
                    padding: "10px",
                  }}
                >
                  {/* © 2021 {sellerData.name}. All rights reserved. */}©
                  2024-2025 | All Rights Reserved
                </p>
              </div>
            </Layout>
            <ToastContainer
              position="top-center"
              autoClose={1000}
              limit={6}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              transition={Slide}
            />
          </SearchProvider>
        </LanguageProvider>
      </CartProvider>
    </div>
  );
};

export default Products;

const processPath = (path: string) => {
  // Step 1: Split the path into parts
  const parts = path.split("/");

  // Step 2: Extract chat ID and cart ID dynamically
  const vendorId = parts[1]; // 2nd part (index 1) is the vendor ID
  const chatId = parts[3]; // 5th part (index 4) is the chat ID
  const cartId = parts[5]; // 7th part (index 6) is the cart ID

  // Step 3: Generate the new path without the cart portion
  const newPath = path.split("/Carts")[0]; // Remove everything after and including "/Carts"

  // Return the results
  return {
    chatId,
    cartId,
    newPath,
    vendorId,
  };
};
