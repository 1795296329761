import React, { createContext, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';

interface LanguageContextProps {
  language: string;
  direction: string;
  setLanguage: (lang: string) => void;
  handleLanguageChange: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextProps>({
  language: "English",
  direction: "ltr",
  setLanguage: () => {},
  handleLanguageChange: () => {},
});

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const savedLanguage = localStorage.getItem("appLanguage") || "English";
  const [language, setLanguage] = useState(savedLanguage);
  const direction = language === "Arabic" ? "rtl" : "ltr";
  const { i18n } = useTranslation();

  const handleLanguageChange = (lang: string) => {
    localStorage.setItem("appLanguage", lang);
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }

  return (
    <LanguageContext.Provider value={{ language, direction, setLanguage,handleLanguageChange }}>
      <div dir={direction}>{children}</div>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
