import React, { createContext, useContext, useState } from "react";

interface SearchContextProps {
  search: string;
  setSearch: (search: string) => void;
  products: any;
  setProducts: (products: any) => void;
  categories: any;
  setCategories: (categories: any) => void;
}

const SearchContext = createContext<SearchContextProps>({
  search: "",
  setSearch: () => {},
  products: [],
  setProducts: () => {},
  categories: [],
  setCategories: () => {},
});

export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  //  console.log(products," seaa")
  //   console.log(categories, " search catge")
  //   console.log(search, " search")
  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        products,
        setProducts,
        categories,
        setCategories,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
