import React from "react";
import { useSearch } from "./contexts/SearchContext";
import { toast } from "react-toastify";
import { GoPlus } from "react-icons/go";
import { ProductModal } from "./CategoryDetail";

const SearchResults = ({ setCart }: { setCart: any }) => {
  const { products, categories, search } = useSearch();
  const [currentModalData, setCurrentModalData] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
//   console.log(products, " seaa");
//   console.log(categories, " search catge");
    // console.log(search, " search");

  return (
    <div>
      <div>
        <h1>Search Results</h1>
        <div>
          <div className="category-container">
            <div id="container-categories">
              <div className="category-grid">
                {categories &&
                  categories?.map((category: any, index: any) => (
                    <div
                      key={index + category?.en?.title}
                      className="category-card"
                    >
                      <img
                        src={category?.en?.image}
                        alt={category?.en?.title}
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h2>Products</h2>
          <div>
            <div className="product-list-item">
              {products.map((product: any, index: any) => (
                <div key={product.id} className="product-card">
                  <div className="image-container">
                    <img
                      src={product?.image_link || ""}
                      alt={product.name}
                      width={127}
                      height={127}
                    />
                    <button
                      className="add-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        //   console.log("product ", product);
                        const updatedProduct = {
                          ...product,
                          quantity: 1,
                        };
                        setCart((prev: any) => {
                          return [...prev, updatedProduct];
                        });
                        toast.success(`${product.name} added to cart`);
                      }}
                    >
                      <GoPlus />
                    </button>
                  </div>
                  <div
                    className="product-details"
                    onClick={() => {
                      setIsOpen(true);
                      setCurrentModalData(product);
                    }}
                  >
                    <p className="product-price">{product.price} BHD</p>
                    <h2 className="product-name">{product.name}</h2>
                  </div>
                </div>
              ))}
            </div>

            {currentModalData && (
              <ProductModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                product={currentModalData}
                setCart={setCart}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
