const Day = ({ date }: { date: string }) => {
  const today = new Date();
  const todayStr = today.toDateString();
  today.setDate(today.getDate() - 1);
  const yesterdayStr = today.toDateString();

  return (
    <li className="chat-list">
      <div className="chat-day-title">
        {date === todayStr ? (
          <span className="title">Today</span>
        ) : date === yesterdayStr ? (
          <span className="title">Yesterday</span>
        ) : (
          <span className="title">{date}</span>
        )}
      </div>
    </li>
  );
};

export default Day;
