import { useEffect, useState } from "react";
import { useRedux } from "./useRedux";
import { useAuthUser } from "./UserHooks";
import { createSelector } from "reselect";
import { RootState } from "../redux/store";
import { Message, SentMessage } from "../types/Messages";
import useChat from "./useChat";
import { db } from "../firebaseConfig";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  setIsFetchingMessages,
  setIsSendingMessage,
  setLastMessage,
  setMessages,
} from "../redux/actions";
import { toast } from "react-toastify";
import { FirestoreChat } from "../types/Chat";

export default function useMessages(
  {
    listenChanges,
  }: {
    listenChanges?: boolean;
  } = { listenChanges: false },
) {
  const { useAppSelector, dispatch } = useRedux();
  const { user_id } = useAuthUser();
  const { selectedChatId, updateChat } = useChat();
  const paginationSize = 10;
  const [totalMessagesLoaded, setTotalMessagesLoaded] =
    useState(paginationSize);

  const chatStoreSelector = createSelector(
    (state: RootState) => state.Chats,
    chats => ({
      messages: chats.messages,
      isSendingMessage: chats.isSendingMessage,
      isFetchingMessages: chats.isFetchingMessages,
      lastMessage: chats.lastMessage,
      hasMoreMessages: chats.hasMoreMessages,
    }),
  );

  const {
    messages,
    isSendingMessage,
    isFetchingMessages,
    lastMessage,
    hasMoreMessages,
  }: {
    messages: Message[];
    isSendingMessage: boolean;
    isFetchingMessages: boolean;
    lastMessage: DocumentData | null;
    hasMoreMessages: boolean;
  } = useAppSelector(chatStoreSelector);

  // Realtime listener for messages collection
  useEffect(() => {
    if (!user_id || !selectedChatId || !listenChanges) return;

    dispatch(setIsFetchingMessages(true));

    const messagesRef = collection(
      db,
      "Vendors",
      user_id,
      "Chats",
      selectedChatId,
      "Messages",
    );
    const q = query(
      messagesRef,
      orderBy("timestamp", "desc"),
      limit(totalMessagesLoaded),
    );

    const unsubscribe = onSnapshot(q, snapshot => {
      const messagesData: Message[] = snapshot.docs
        .map((doc): Message => {
          const data = doc.data() as Message;
          data.id = doc.id;
          return data;
        })
        .reverse(); // reverse the response because the newest is on top

      console.log("length of messages is " + messagesData.length);
      console.log("messages are ", messagesData);
      dispatch(setMessages(messagesData));
      dispatch(
        setLastMessage(
          snapshot.docs.length < totalMessagesLoaded
            ? null
            : (snapshot.docs[snapshot.docs.length - 1] ?? null),
        ),
      );
      dispatch(setIsFetchingMessages(false));
    });

    // Cleanup function to unsubscribe from snapshot listener
    return () => unsubscribe();
  }, [user_id, selectedChatId, listenChanges, totalMessagesLoaded]);

  const loadMore = async () => {
    if (!user_id || !selectedChatId || isFetchingMessages || !lastMessage)
      return;
    setTotalMessagesLoaded(prev => prev + paginationSize);
  };

  const sendMessage = async (message: SentMessage, chat_id?: string) => {
    if (!chat_id && selectedChatId) chat_id = selectedChatId;

    if (!user_id) {
      toast.error("User id not found");
      return;
    }

    if (!chat_id) {
      toast.error("Chat id not found");
      return;
    }

    dispatch(setIsSendingMessage(true));

    if (chat_id && user_id) {
      // vendor doc
      const vendorDocRef = doc(db, "Vendors", user_id);
      const vendorSnap = await getDoc(vendorDocRef);
      if (!vendorSnap.exists) {
        return toast.error("Vendor document not found");
      }
      const vendorData = vendorSnap.data();
      const companyName = vendorData?.companyName;

      // chat doc
      const chatRef = doc(vendorDocRef, "Chats", chat_id);
      const chatSnap = await getDoc(chatRef);
      if (!chatSnap.exists) {
        return toast.error("Chat document not found");
      }
      const chatData = chatSnap.data() as FirestoreChat;

      // message doc
      const messagesCollection = collection(chatRef, "Messages");
      const messageTimestamp = serverTimestamp() as Timestamp;

      const data: SentMessage = {
        ...message,
        timestamp: messageTimestamp,
        sender: companyName,
        senderId: chat_id,
      };
      await addDoc(messagesCollection, data);

      // update last message and storage records on the chat document
      let chatStorage = chatData.storage ?? 0;
      let filesize = 0;
      switch (message.payload.type) {
        case "text":
          await updateChat(
            {
              last_message: message.payload.text.body,
              last_message_time: messageTimestamp,
            },
            chat_id,
          );
          break;
        case "image":
          filesize = message.payload.image.filesize;
          chatStorage = filesize + chatStorage;
          await updateChat(
            {
              last_message: message.payload.image.caption
                ? message.payload.image.caption
                : "You sent an image",
              last_message_time: messageTimestamp,
              storage: chatStorage,
            },
            chat_id,
          );
          break;
        case "video":
          filesize = message.payload.video.filesize;
          chatStorage = filesize + chatStorage;
          await updateChat(
            {
              last_message: message.payload.video.caption
                ? message.payload.video.caption
                : "You sent a video",
              last_message_time: messageTimestamp,
              storage: chatStorage,
            },
            chat_id,
          );
          break;
        case "document":
          filesize = message.payload.document.filesize;
          chatStorage = filesize + chatStorage;
          await updateChat(
            {
              last_message: message.payload.document.caption
                ? message.payload.document.caption
                : "You sent a document",
              last_message_time: messageTimestamp,
              storage: chatStorage,
            },
            chat_id,
          );
          break;
        case "audio":
          filesize = message.payload.audio.filesize;
          chatStorage = filesize + chatStorage;
          await updateChat(
            {
              last_message: message.payload.audio.caption
                ? message.payload.audio.caption
                : "You sent an audio",
              last_message_time: messageTimestamp,
              storage: chatStorage,
            },
            chat_id,
          );
          break;
      }

      // update storage on vendor if message has filesize
      if (filesize) {
        let vendor_storage = filesize + (vendorData?.storage ?? 0);
        await updateDoc(vendorDocRef, { storage: vendor_storage });
      }
    }

    dispatch(setIsSendingMessage(false));
  };

  return {
    messages,
    sendMessage,
    isSendingMessage,
    isFetchingMessages,
    lastMessage,
    hasMoreMessages,
    loadMore,
  };
}
