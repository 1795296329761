import React, { useEffect } from "react";
import SearchResults from "./SearchResults";
import About from "./About";
import Categories from "./Categories";
import CategoryDetail from "./CategoryDetail";
import { useSearch } from "./contexts/SearchContext";
import { useCart } from "./contexts/CartContext";

const Hero = ({
  categories,
  sellerData,
  inventory,
  setCart,
  initialCategory,
  setInitialCategory,
  loading,
}: {
  categories: any;
  sellerData: any;
  inventory: any;
  setCart: any;
  initialCategory: any;
  setInitialCategory: any;
  loading: any;
}) => {
  const { search } = useSearch();
  const {setVendorId} = useCart();

  useEffect(() => {
    if(sellerData?.id){
      setVendorId(sellerData?.id)
    }
  }, [sellerData])

  // console.log(search, " search ")
  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <h1>Loading...</h1>
          </div>
        </>
      ) : search && search?.length > 1 ? (
        <>
          <SearchResults setCart={setCart} />
        </>
      ) : (
        <>
          {" "}
          <About sellerData={sellerData} />
          { !sellerData?.name ? (
            <div className="empty-seller"
           
            ></div>
          ) : initialCategory === null ? (
            <>
              <Categories
                Category={categories}
                sellerData={sellerData}
                setInitialCategory={setInitialCategory}
              />
            </>
          ) : (
            <>
              <CategoryDetail
                Category={categories}
                products={inventory}
                sellerData={sellerData}
                setCart={setCart}
                initialCategory={initialCategory}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Hero;
