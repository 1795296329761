import { useEffect, useState } from "react";
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
import { AudioRecorder } from "react-audio-voice-recorder";
import { toast } from "react-toastify";

interface EndButtonsProps {
  onSubmit: () => void;
  onAudioRecordingCompleted: (audio: File) => void;
  disabled: boolean;
  isSending: boolean;
}

const EndButtons = ({
  onSubmit,
  onAudioRecordingCompleted,
  disabled,
  isSending,
}: EndButtonsProps) => {
  const [hasAudio, setHasAudio] = useState(true);

  useEffect(() => {
    if (!navigator.mediaDevices) {
      toast.error("Audio recording unavailable");
      setHasAudio(false);
    }
  }, []);

  const addAudioElement = (blob: Blob) => {
    const file = new File([blob], "recording.webm", { type: "audio/webm" });
    onAudioRecordingCompleted(file);   
    
  };

  return (
    <div className="chat-input-links ms-2 gap-md-1">
      {hasAudio && (
        <div className="me-1">
          <AudioRecorder
            onRecordingComplete={addAudioElement}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadFileExtension="webm"
            onNotAllowedOrFound={e => console.log("test", e)}
            showVisualizer={true}
          />
        </div>
      )}
      {/* <div className="links-list-item d-none d-sm-block">
        <Button
          color="none"
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          id="audio-btn"
        >
          <i className="bx bx-microphone align-middle"></i>
        </Button>
      </div>
      <UncontrolledPopover
        trigger="focus"
        placement="top"
        target="audio-btn"
        transition={{ timeout: 200 }} // Disable fade transitions
        // Transition duration in milliseconds
      >
        <PopoverBody>
          <div className="loader-line">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </PopoverBody>
      </UncontrolledPopover> */}
      <div className="links-list-item">
        <Button
          color="primary"
          type="submit"
          disabled={disabled || isSending}
          className="btn btn-primary btn-lg chat-send waves-effect waves-light d-flex justify-content-center align-items-center"
        >
          {isSending ? (
            <div
              className="spinner-border spinner-border-sm"
              style={{ fontSize: "16px" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <i className="bx bxs-send align-middle"></i>
          )}
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;
