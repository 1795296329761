import React, { useState, useRef, useEffect, Fragment } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import { IoIosAddCircleOutline } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
// import { FaShoppingCart } from "react-icons/fa";
import "../../assets/scss/custom/pages/_categoryDetail.scss";
import "../../assets/scss/custom/pages/_product_modal.scss";
import { MdOutlineClose } from "react-icons/md";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Product from "./Product";
import { useCart } from "./contexts/CartContext";

const CategoryDetail = ({
  products,
  Category,
  setCart,
  initialCategory,
  sellerData,
}: {
  products: any;
  Category: any;
  setCart: any;
  initialCategory: any;
  sellerData: any;
}) => {
  console.log("products ", products);
  console.log("Category ", Category);
  console.log("initialCategory ", initialCategory);
  const firstCategory = (initialCategory && initialCategory?.en?.title) || "";
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [activeCategory, setActiveCategory] = useState(firstCategory);
  const [visibleSubCategory, setVisibleSubCategory] = useState("");
  const [currentModalData, setCurrentModalData] = useState<any>(null);
  const [fetchCombinedData, setFetchCombinedData] = useState<any[]>([]);

  const categoryListRef = useRef<HTMLDivElement>(null);
  const subCategoryRefs = useRef<(HTMLDivElement | null)[]>([]);
  // console.log("subCategoryRefs ", subCategoryRefs)
  //   console.log("activeCategory ", activeCategory);

  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = (direction: "left" | "right") => {
    if (categoryListRef.current) {
      const scrollAmount = 160;
      categoryListRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (!fetchCombinedData?.length) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const visibleEntry = entries.find((entry) => entry.isIntersecting);
        if (visibleEntry) {
          setVisibleSubCategory(
            visibleEntry.target.getAttribute("data-subcategory") || ""
          );
        }
      },
      { root: document.querySelector(".product-list"), threshold: 0.5 }
    );

    subCategoryRefs.current.forEach((ref) => ref && observer.observe(ref));

    return () => {
      subCategoryRefs.current.forEach((ref) => ref && observer.unobserve(ref));
    };
  }, [fetchCombinedData]);

  useEffect(() => {
    if (products) {
      setActiveCategory(firstCategory);
      const subCategories = products.reduce((acc: any, product: any) => {
        if (acc[product.category]) {
          acc[product.category].push(product);
        } else {
          acc[product.category] = [product];
        }
        return acc;
      }, {});

      const newSubCategories = products.reduce((acc: any, product: any) => {
        // console.log(acc," product.category ", product);
        if (acc[product.catalog]) {
          acc[product.catalog].push(product);
        } else {
          acc[product.catalog] = [product];
        }
        // console.log("acc ", acc);
        return acc;
      }, {});

      // console.log("subCategories N ", newSubCategories);

      const newProductsList = Category?.map((category: any) => {
        const MainCategory = category?.en?.title;
        const subCateg = category?.en?.subcategories?.map(
          (subCategory: any) => {
            // console.log("subCategory ", subCategory);
            return {
              subCategory: subCategory,
              products: newSubCategories[subCategory],
            };
          }
        );
        // console.log("subCateg ", subCateg);

        return {
          MainCategory,
          subCateg,
        };
      });
      console.log("newProductsList ", newProductsList);

      //   const combinedData = Object.keys(subCategories).map((subCategory) => ({
      //     subCategory,
      //     products: subCategories[subCategory],
      //   }));

      setFetchCombinedData(newProductsList);
      //   console.log("combinedData details ", combinedData)
    }
  }, [products]);

  return (
    <div className="category-details" id="category-details">
      <div className="category-detail-container">
        <div className="category-header sticky">
          <h1 className="title">{Category?.title}</h1>
          <div className="category-slider">
            <button
              className="slider-btn left"
              onClick={() => handleScroll("left")}
            >
              <FaChevronLeft />
            </button>

            <div className="category-list" ref={categoryListRef}>
              {Category &&
                Category?.map((category: any, index: any) => (
                  <p
                    key={category.id}
                    className={`category-item ${activeCategory === category?.en?.title ? "active" : ""}`}
                    onClick={() => setActiveCategory(category?.en?.title)}
                  >
                    <span>{category?.en?.title}</span>
                  </p>
                ))}
            </div>

            <button
              className="slider-btn right"
              onClick={() => handleScroll("right")}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>

        <div id="sticky-header" className="sub-category-header sticky">
          <div className="sub-category-list">
            {fetchCombinedData?.length > 0 &&
              fetchCombinedData?.map((data, index) => {
                return (
                  <>
                    {activeCategory === data.MainCategory &&
                      data.subCateg?.map((subCategory: any, index: any) => (
                        <p
                          key={subCategory.subCategory}
                          className={`sub-category-item ${visibleSubCategory === subCategory.subCategory ? "active" : ""}`}
                          onClick={() => {
                            setVisibleSubCategory(subCategory.subCategory);
                            subCategoryRefs.current[index]?.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                          }}
                        >
                          <span>{subCategory.subCategory}</span>
                        </p>
                      ))}
                  </>
                );
              })}
          </div>
        </div>

        <div className="product-list">
          {fetchCombinedData?.map((data, index) => (
            <Fragment key={data.MainCategory}>
              {activeCategory === data.MainCategory && (
                <div>
                  {data.subCateg?.map((subCategory: any, index: any) => (
                    <div
                      key={subCategory.subCategory}
                      //   className="product-sub-category"
                      data-subcategory={subCategory.subCategory}
                      ref={(el) => (subCategoryRefs.current[index] = el)}
                    >
                      <h3 className="sub-category-title">
                        {subCategory.subCategory}
                      </h3>
                      <div className="product-list-item">
                        {subCategory?.products?.map(
                          (product: any, index: any) => (
                            <Product
                              key={product.id}
                              product={product}
                              setCart={setCart}
                              setCurrentModalData={setCurrentModalData}
                              setIsOpen={setIsOpen}
                              toast={toast}
                              sellerData={sellerData}
                            />
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Fragment>
          ))}

          {currentModalData && (
            <ProductModal
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              product={currentModalData}
              setCart={setCart}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryDetail;

// interface ProductModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   product: {
//     name: string;
//     price: string;
//     image_link: string;
//     description: string;
//   };
//   setCart: any;
// }

export const ProductModal: React.FC<any> = ({
  isOpen,
  onClose,
  product,
  setCart,
}) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };
  const { t } = useTranslation();
  const {addProductToCart} = useCart();

  const updateProductCart = async (product: any) => {
   
    const res = await addProductToCart(product);
    if (res === true) {
      setCart((prev: any) => {
        return [...prev, product];
      });
      onClose();
    }
  };
  return (
    <div className={`modal product-modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content animated-modal">
        <div className="cardss">
          {/* <div className="quickview-loader is-active">
                        <div className="loader is-loading"></div>
                    </div> */}
          {!product?.title && (
            <div className="quickview-loader is-active">
              <div className="loader is-loading"></div>
            </div>
          )}

          <button className="modal-close is-large" onClick={onClose}>
            <MdOutlineClose />
          </button>

          <div className="modal-body">
            {/* Left Section */}
            <div className="left">
              <div className="product-image is-active">
                <img src={product?.image_link} alt={product?.title} />
              </div>
            </div>

            {/* Right Section */}
            <div className="right">
              <div className="header">
                <div className="product-info">
                  <h3 id="quickview-name">{product?.title}</h3>
                  <p>Best Quality Product</p>
                </div>
                <div id="quickview-price" className="price">
                  {product.price}
                </div>
              </div>

              {/* Product Properties */}
              <div className="properties">
                <p>{product?.description}</p>
              </div>

              {/* Description */}
              <div className="quickview-description content has-slimscroll">
                {/* <p>{product?.description}</p> */}
              </div>

              <div className="quickview-controls">
                {/* Quantity Spinner */}
                <div className="spinner">
                  <button className="remove" onClick={handleDecrease}>
                    <AiOutlineMinus size={14} />
                  </button>
                  <span className="value">{quantity}</span>
                  <button className="add" onClick={handleIncrease}>
                    <AiOutlinePlus size={14} />
                  </button>
                  <input
                    className="spinner-input"
                    type="hidden"
                    value={quantity}
                    readOnly
                  />
                </div>

                {/* Add to Cart Button */}
                <button
                  className="button is-solid accent-button raised"
                  onClick={() => {
                    const updatedProduct = {
                      ...product,
                      quantity,
                    };
                    updateProductCart(updatedProduct);
                  }}
                >
                  {/* <FaShoppingCart size={16} /> */}
                  <span>Add To Cart</span>
                  {/* <var id="quickview-button-price">{12}</var> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
