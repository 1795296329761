import React from "react";
import classnames from "classnames";

// hooks
import { useRedux } from "../hooks/index";

// hooks
import { useConversationUserType } from "../hooks/index";
import { createSelector } from "reselect";
// component
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import Welcome from "./ConversationUser/Welcome";
import { TABS } from "../constants";
import Transactions from "./Transactions/Transaction";
import Orders from "./Order/Orders";
import Inventory from "./Inventory/Inventory";
import Vendors from "./Vendors/Vendors";
import useChat from "../hooks/useChat";

interface IndexProps {}
const Index = (props: IndexProps) => {
  // global store
  const { useAppSelector } = useRedux();

  const { selectedChat } = useChat();

  const { isChannel } = useConversationUserType();

  // Check if the active tab is Inventory
  const layoutData = createSelector(
    (state: any) => state.Layout,
    state => ({
      activeTab: state.activeTab,
    }),
  );
  const { activeTab } = useAppSelector(layoutData);
  return (
    <>
      {/* Conditionally render Leftbar based on the activeTab */}
      {activeTab !== TABS.INVENTORY &&
        activeTab !== TABS.ORDERS &&
        activeTab !== TABS.TRANSACTIONS &&
        activeTab !== TABS.VENDORS && <Leftbar />}

      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>

        {/* Render components based on the activeTab */}
        {activeTab === TABS.INVENTORY ? (
          <Inventory /> // Show Inventory when Inventory tab is selected
        ) : activeTab === TABS.VENDORS ? (
          <Vendors /> // Show Inventory when Inventory tab is selected
        ) : activeTab === TABS.ORDERS ? (
          <Orders /> // Show Orders when Orders tab is selected
        ) : activeTab === TABS.TRANSACTIONS ? (
          <Transactions /> // Show Transactions when Transactions tab is selected
        ) : selectedChat !== null ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative d-flex flex-column vh-100">
              <ConversationUser isChannel={isChannel} />
            </div>
            <UserProfileDetails isChannel={isChannel} />
          </div>
        ) : (
          <Welcome />
        )}
      </div>
    </>
  );
};
export default Index;
