import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  UncontrolledTooltip,
  Fade,
} from "reactstrap";
import { createSelector } from "reselect";
import { useRedux } from "../../hooks/index";
import { changeTab } from "../../redux/actions";
import { TABS } from "../../constants/index";
import LightDarkMode from "../../components/LightDarkMode";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { MENU_ITEMS, MenuItemType } from "./menu";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig"; // Import the Firebase configuration

const LogoLightSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z" />
    </svg>
  );
};

const LogoDarkSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path d="M8.5,18l3.5,4l3.5-4H19c1.103,0,2-0.897,2-2V4c0-1.103-0.897-2-2-2H5C3.897,2,3,2.897,3,4v12c0,1.103,0.897,2,2,2H8.5z M7,7h10v2H7V7z M7,11h7v2H7V11z" />
    </svg>
  );
};

const Logo = () => {
  return (
    <div className="navbar-brand-box">
      <Link to="#" className="logo logo-dark">
        <span className="logo-sm">
          <LogoLightSVG />
        </span>
      </Link>

      <Link to="#" className="logo logo-light">
        <span className="logo-sm">
          <LogoDarkSVG />
        </span>
      </Link>
    </div>
  );
};

interface MenuNavItemProps {
  item: MenuItemType;
  selectedTab:
  | TABS.CHAT
  | TABS.SETTINGS
  | TABS.USERS
  | TABS.INVENTORY
  | TABS.ORDERS
  | TABS.TRANSACTIONS
  | TABS.VENDORS
  onChangeTab: (
    id:
      | TABS.CHAT
      | TABS.SETTINGS
      | TABS.USERS
      | TABS.INVENTORY
      | TABS.ORDERS
      | TABS.TRANSACTIONS
      | TABS.VENDORS
  ) => void;
}

const MenuNavItem = ({ item, selectedTab, onChangeTab }: MenuNavItemProps) => {
  const onClick = () => {
    onChangeTab(item.tabId);
  };
  return (
    <>
      <NavItem className={item.className} id={`${item.key}-container`}>
        <NavLink
          href={item.url}
          active={selectedTab === item.tabId}
          id={item.key}
          role="tab"
          onClick={onClick}
        >
          <i className={item.icon}></i>
        </NavLink>
      </NavItem>
      <UncontrolledTooltip
        target={`${item.key}-container`}
        placement="right"
        transition={{ timeout: 200 }}
      >
        {item.tooltipTitle}
      </UncontrolledTooltip>
    </>
  );
};

interface ProfileDropdownMenuProps {
  onChangeTab: (
    id:
      | TABS.CHAT
      | TABS.SETTINGS
      | TABS.USERS
      | TABS.INVENTORY
      | TABS.ORDERS
      | TABS.TRANSACTIONS
      | TABS.VENDORS
  ) => void;
}

const ProfileDropdownMenu = ({ onChangeTab }: ProfileDropdownMenuProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown
      nav
      isOpen={dropdownOpen}
      className="profile-user-dropdown"
      toggle={toggle}
    >
      <DropdownToggle nav className="bg-transparent">
        <img src={avatar1} alt="" className="profile-user rounded-circle" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => onChangeTab(TABS.USERS)}
        >
          Profile <i className="bx bx-user-circle text-muted ms-1"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => onChangeTab(TABS.SETTINGS)}
        >
          Setting <i className="bx bx-cog text-muted ms-1"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          href="/auth-changepassword"
        >
          Change Password <i className="bx bx-lock-open text-muted ms-1"></i>
        </DropdownItem>
        <DropdownItem />
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          tag="a"
          href="/logout"
        >
          Log out <i className="bx bx-log-out-circle text-muted ms-1"></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const SideMenu = ({ onChangeLayoutMode }: any) => {
  const { dispatch, useAppSelector } = useRedux();
  const menuItems: MenuItemType[] = MENU_ITEMS;
  const navigate = useNavigate();

  const errorData = createSelector(
    (state: any) => state.Layout,
    (state) => ({
      activeTab: state.activeTab,
      layoutMode: state.layoutMode,
    })
  );

  const { activeTab, layoutMode } = useAppSelector(errorData);

  const [selectedTab, setSelectedTab] = useState<
    | TABS.CHAT
    | TABS.SETTINGS
    | TABS.USERS
    | TABS.INVENTORY
    | TABS.ORDERS
    | TABS.TRANSACTIONS
    | TABS.VENDORS
  >(TABS.CHAT);

  const [isOwner, setIsOwner] = useState(false);

  const onChangeTab = (
    id:
      | TABS.CHAT
      | TABS.SETTINGS
      | TABS.USERS
      | TABS.INVENTORY
      | TABS.ORDERS
      | TABS.TRANSACTIONS
      | TABS.VENDORS
  ) => {
    setSelectedTab(id);
    localStorage.setItem("activeTab", id);
    dispatch(changeTab(id));
  };


  return (
    <div className="side-menu flex-lg-column">
      <Logo />
      <div className="flex-lg-column my-0 sidemenu-navigation">
        <Nav pills className="side-menu-nav" role="tablist">
          <MenuNavItem
            item={menuItems[0]}
            selectedTab={selectedTab}
            onChangeTab={onChangeTab}
          />

          <LightDarkMode
            layoutMode={layoutMode}
            onChangeLayoutMode={onChangeLayoutMode}
          />
          <ProfileDropdownMenu onChangeTab={onChangeTab} />
        </Nav>
      </div>
    </div>
  );
};

export default SideMenu;
