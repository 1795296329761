import { FaEdit, FaLocationArrow, FaTimes, FaTrash } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import "../../assets/scss/custom/pages/_address_modal.scss";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";

const AddressModal = ({
  modalOpen,
  toggleModal,
  WAPhone
}: {
  modalOpen: boolean;
  toggleModal: () => void;
  WAPhone: string | null;
}) => {
  
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [savedLocations, setSavedLocations] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState<string | null>(null); // ID of the location being edited
  const [locationAllowed, setLocationAllowed] = useState<boolean>(
    localStorage.getItem("locationAllowed") === "true"
  );
  const [loading, setLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);

  const fetchLocations = async () => {
    if (!WAPhone) return;

    setLoading(true);
    try {
      const q = query(
        collection(db, "Locations"),
        where("WAPhone", "==", WAPhone)
      );
      const querySnapshot = await getDocs(q);

      const locations: any[] = [];
      querySnapshot.forEach((doc) => {
        locations.push({ id: doc.id, ...doc.data() });
      });

      setSavedLocations(locations);
    } catch (error) {
      console.error("Error fetching locations:", error);
      // alert("Failed to fetch locations.");
      toast.error(`${t("addressModal.locationFetchError")}`);
    } finally {
      setLoading(false);
    }
  };

  const saveLocation = async () => {
    if (!WAPhone || !address || !name) {
      // alert("Please provide both name and address.");
      toast.error("Please provide both name and address.");
      return;
    }

    setLoading(true);
    try {
      if (isEditing) {
        const locationRef = doc(db, "Locations", isEditing);
        await updateDoc(locationRef, { name, address });
        // alert("Location updated successfully!");
        toast.success(`${t("addressModal.locationUpdatedSuccess")}`);
      } else {
        const locationData = {
          WAPhone,
          name,
          address,
        };
        await setDoc(doc(collection(db, "Locations")), locationData);
        // alert("Location added successfully!");
        toast.success(`${t("addressModal.locationAddedSuccess")}`);
      }

      setName("");
      setAddress("");
      setIsEditing(null);
      fetchLocations();
    } catch (error) {
      console.error("Error saving location:", error);
      // alert("Failed to save location.");
      toast.error(`${t("addressModal.locationSaveError")}`);
    } finally {
      setLoading(false);
    }
  };

  const deleteLocation = async (id: string) => {
    setLoading(true);
    try {
      await deleteDoc(doc(db, "Locations", id));
      // alert("Location deleted successfully!");
      toast.success(`${t("addressModal.locationDeletedSuccess")}`);
      fetchLocations();
    } catch (error) {
      console.error("Error deleting location:", error);
      toast.error(`${t("addressModal.locationDeleteError")}`);
      // alert("Failed to delete location.");
    } finally {
      setLoading(false);
    }
  };

  const editLocation = (location: {
    id: string;
    name: string;
    address: string;
  }) => {
    setIsEditing(location.id);
    setName(location.name);
    setAddress(location.address);
  };

  const handleAllowLocation = () => {
    setLocationAllowed(true);
    getUserLocation();
    // localStorage.setItem("locationAllowed", "true"); // Save state to local storage
    // fetchLocations();
  };

  useEffect(() => {
    if (modalOpen && locationAllowed) {
      fetchLocations();
    }
  }, [modalOpen, locationAllowed]);

  const [location, setLocation] = useState<any>({
    latitude: null,
    longitude: null,
    address: "",
  });

  // Get the user's location
  const getUserLocation = () => {
    setAddressLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation((prev: any) => ({ ...prev, latitude, longitude }));
          getAddressFromCoords(latitude, longitude);
        },
        (error) => {
          toast.error("Error getting location");
          console.error(error);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  // Reverse Geocode using OpenStreetMap Nominatim API
  const getAddressFromCoords = async (latitude: any, longitude: any) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

    try {
      const response = await axios.get(url, {
        headers: { "User-Agent": "Location-App" },
      });

      // @ts-ignore
      if (response && response.display_name) {
        // @ts-ignore
        const address = response.display_name;
        // setLocation((prev:any) => ({ ...prev, address }));
        setAddress(address);
      } else {
      }
    } catch (err) {
      console.error(err);
    } finally {
      setAddressLoading(false);
    }
  };

  return (
    <div className={`custom-modal ${modalOpen ? "open" : ""}`}>
      <div className="modal-overlay" onClick={toggleModal}></div>
      <div className="modal-content">
        <div className="modal-header">
          <h2>{t("addressModal.manageLocations")}</h2>
          <button className="close-btn" onClick={toggleModal}>
            <FaTimes />
          </button>
        </div>

        <div className="modal-body">
          {loading && (
            <div className="loading-spinner"> {t("addressModal.loading")}</div>
          )}

          {!locationAllowed && !loading ? (
            <div className="location-options">
              <button className="allow-btn" onClick={handleAllowLocation}>
                <FaLocationArrow /> {t("addressModal.allowLocation")}
              </button>
              <p className="notice">
                {t("addressModal.allowLocationNotice")}
                <strong> {t("addressModal.withoutLocationAccess")}</strong>
              </p>
              <ul>
                <li> {t("addressModal.cannotSaveLocations")}</li>
                <li> {t("addressModal.cannotUseLocationServices")}</li>
              </ul>
            </div>
          ) : (
            !loading && (
              <>
                <div className={`input-section ${isEditing ? "editing" : ""}`}>
                  <input
                    type="text"
                    placeholder={`${t("addressModal.enterName")}`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <textarea
                    placeholder={
                      addressLoading
                        ? "fetching address..."
                        : `${t("addressModal.enterAddress")}`
                    }
                    value={address}
                    disabled={addressLoading}
                    onChange={(e) => setAddress(e.target.value)}
                    // placeholder={location.address || `${t("addressModal.enterAddress")}`}
                  />
                  <div className="action-buttons">
                    <button
                      className="save-btn"
                      onClick={saveLocation}
                      disabled={loading}
                    >
                      {isEditing
                        ? `${t("addressModal.updateLocation")}`
                        : `${t("addressModal.addLocation")}`}
                    </button>
                    {isEditing && (
                      <button
                        className="cancel-edit-btn"
                        onClick={() => {
                          setIsEditing(null);
                          setName("");
                          setAddress("");
                        }}
                      >
                        {t("addressModal.cancelEdit")}
                      </button>
                    )}
                  </div>
                </div>

                <hr />

                <div className="saved-locations">
                  <h3>
                    {/* Saved Locations */}
                    {t("addressModal.savedLocations")}
                  </h3>
                  {savedLocations.length > 0 ? (
                    savedLocations.map((loc) => (
                      <div key={loc.id} className="location-card">
                        <div className="location-details">
                          <h4>{loc.name}</h4>
                          <p>{loc.address}</p>
                        </div>
                        <div className="location-actions">
                          <button
                            onClick={() => editLocation(loc)}
                            className="edit-btn"
                          >
                            <FaEdit /> {t("addressModal.edit")}
                          </button>
                          <button
                            onClick={() => deleteLocation(loc.id)}
                            className="delete-btn"
                          >
                            <FaTrash /> {t("addressModal.delete")}
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>
                      {/* No saved locations found. */}
                      {t("addressModal.noLocationsFound")}
                    </p>
                  )}
                </div>
              </>
            )
          )}
        </div>

        <div className="modal-footer">
          <button className="close-modal-btn" onClick={toggleModal}>
            {t("addressModal.close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
