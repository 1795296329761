import React, { useEffect } from "react";
import { useCart } from "./contexts/CartContext";
import { useParams } from "react-router-dom";

const Layout = ({
  children,
  chtId,
  carId,
  vedorId,
  cart
}: {
  children: React.ReactNode;
  chtId: string;
  carId: string;
  vedorId: string;
  cart: any[];
}) => {
  const param = useParams();
  const { setCartId, setChatId, setVendorId,addProductToCart,setCartProducts } = useCart();
    // console.log("cart useCart ",cartId,chatId,vendorId );
    // console.log("pms chtId ",chtId,carId,vedorId );

  useEffect(() => {
    if (param?.cartID) {
      setCartId(param.cartID || carId);
      setChatId(chtId);
      setVendorId(vedorId);
    }
  }, [param?.cartId,chtId,vedorId]);

  useEffect(() => {
    if(cart && cart?.length>0){
      // console.log("cart useCart ",cart );
      setCartProducts(cart);
    }
  }, [cart]);

  // console.log("param", param);
  return <>{children}</>;
};

export default Layout;
