import { format } from "date-fns";
import { Timestamp } from "firebase/firestore";

const formateDate = (date: any, formate?: string) => {
  const dateObj = new Date(date);
  const dateFormat = formate ? formate : "MM/dd/yyyy";
  const formattedDate = format(dateObj, dateFormat);
  return formattedDate;
};

const firebaseTimestampToDate = (timestamp: Timestamp):Date=>{
  return timestamp.toDate();
}

export { formateDate, firebaseTimestampToDate };
