import React from "react";
import { useTranslation } from "react-i18next";

const PaymentFailed = (
  { subtotal, shipping, taxes, total }: { subtotal: number; shipping: number; taxes: number; total: number }
) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="failure-box">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="70"
          height="70"
          className="failure-icon"
        >
          <circle
            cx="35"
            cy="35"
            r="24"
            stroke="#e74c3c"
            fill="transparent"
            className="circle-animation"
          />
          <line
            x1="25"
            y1="25"
            x2="45"
            y2="45"
            stroke="#e74c3c"
            strokeWidth="2"
            className="cross-animation"
          />
          <line
            x1="45"
            y1="25"
            x2="25"
            y2="45"
            stroke="#e74c3c"
            strokeWidth="2"
            className="cross-animation"
          />
        </svg>
        <h3>{t("checkout.paymentFailure")}</h3>
        <p>{t("checkout.paymentErrorMessage")}</p>

        <div className="order-summary">
          <h4>{t("checkout.orderSummary")}</h4>
          <div className="order-line">
            <span>{t("checkout.transactionId")}</span>
            <span>#45894</span>
          </div>
          <div className="order-line">
            <span>{t("checkout.subtotal")}</span>
            <span>{subtotal.toFixed(2)} BHD</span>
          </div>
          <div className="order-line">
            <span>{t("checkout.shipping")}</span>
            <span>{shipping.toFixed(2)} BHD</span>
          </div>
          <div className="order-line">
            <span>{t("checkout.taxes")}</span>
            <span>{taxes.toFixed(2)} BHD</span>
          </div>
          <div className="order-line is-total">
            <span>{t("checkout.total")}</span>
            <span>{total.toFixed(2)} BHD</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
