import { useTranslation } from "react-i18next";

const PaymentSuccess = (
    { subtotal, shipping, taxes, total }: { subtotal: number; shipping: number; taxes: number; total: number }
) => {
    const { t } = useTranslation();
  return (
    <>
     <div className="confirmation-box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="70"
                className="confirmation-icon"
              >
                <circle
                  cx="35"
                  cy="35"
                  r="24"
                  stroke="#00b894"
                  fill="transparent"
                  className="circle-animation"
                />
                <polyline
                  points="23 34 34 43 47 27"
                  stroke="#00b894"
                  strokeWidth="2"
                  fill="transparent"
                  className="checkmark-animation"
                />
              </svg>
              <h3>{t("checkout.paymentSuccess")}</h3>
              <p>{t("checkout.paymentThankYou")}</p>

              <div className="order-summary">
                <h4>{t("checkout.orderSummary")}</h4>
                <div className="order-line">
                  <span>{t("checkout.transactionId")}</span>
                  <span>#45894</span>
                </div>
                <div className="order-line">
                  <span>{t("checkout.subtotal")}</span>
                  <span>{subtotal.toFixed(2)} BHD</span>
                </div>
                <div className="order-line">
                  <span>{t("checkout.shipping")}</span>
                  <span>{shipping.toFixed(2)} BHD</span>
                </div>
                <div className="order-line">
                  <span>{t("checkout.taxes")}</span>
                  <span>{taxes.toFixed(2)} BHD</span>
                </div>
                {/* <div className="order-line">
                  <span>Discount</span>
                  <span>{discount.toFixed(2)} BHD</span>
                </div> */}
                <div className="order-line is-total">
                  <span>{t("checkout.total")}</span>
                  <span>
                    {/* {Number(total.toFixed(2)) - Number(discount.toFixed(2))} BHD */}
                    {total.toFixed(2)} BHD
                  </span>
                </div>
              </div>
            </div>
    </>
  )
}

export default PaymentSuccess