import { useEffect, useState } from "react";

// hooks
import useMessages from "../../hooks/useMessages";
import useChat from "../../hooks/useChat";
// import { useRedux } from "../../hooks/index";
// import { createSelector } from "reselect";
// actions
// import {
//   toggleUserDetailsTab,
//   getChatUserConversations,
//   // onSendMessage,
//   // receiveMessage,
//   // readMessage,
//   // receiveMessageFromUser,
//   deleteMessage,
//   deleteUserMessages,
//   toggleArchiveContact,
// } from "../../redux/actions";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface

// dummy data
import { pinnedTabs } from "../../data/index";
import { SentMessage } from "../../types/Messages";

interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  // const { dispatch, useAppSelector } = useRedux();
  const { selectedChat } = useChat();
  const {
    messages,
    sendMessage,
    isFetchingMessages,
    loadMore,
    hasMoreMessages,
  } = useMessages({
    listenChanges: true,
  });

  // const errorData = createSelector(
  //   (state: any) => state.Chats,
  //   state => ({
  //     chatUserDetails: state.chatUserDetails,
  //     isUserMessageSent: state.isUserMessageSent,
  //     isMessageDeleted: state.isMessageDeleted,
  //     isMessageForwarded: state.isMessageForwarded,
  //     isUserMessagesDeleted: state.isUserMessagesDeleted,
  //     isImageDeleted: state.isImageDeleted,
  //   }),
  // );
  // Inside your component
  // const {
  //   // chatUserDetails,
  //   isUserMessageSent,
  //   isMessageDeleted,
  //   isMessageForwarded,
  //   isUserMessagesDeleted,
  //   isImageDeleted,
  // } = useAppSelector(errorData);

  const onOpenUserDetails = () => {
    // dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<any | null | undefined>();
  const onSetReplyData = (reply: any) => {
    // console.log({ reply });
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: SentMessage) => {
    // let params: any = {
    //   text: data.text && data.text,
    //   time: new Date().toISOString(),
    //   image: data.image && data.image,
    //   newimage: data.newimage && data.newimage,
    //   attachments: data.attachments && data.attachments,
    //   meta: {
    //     receiver: chatUserDetails.id,
    //     sender: userProfile.uid,
    //   },
    // };

    // if (replyData && replyData !== null) {
    //   params["replyOf"] = replyData;
    // }

    // dispatch(onSendMessage(params));
    // if (!isChannel) {
    //   setTimeout(() => {
    //     dispatch(receiveMessage(chatUserDetails.id));
    //   }, 1000);
    //   setTimeout(() => {
    //     dispatch(readMessage(chatUserDetails.id));
    //   }, 1500);
    //   setTimeout(() => {
    //     dispatch(receiveMessageFromUser(chatUserDetails.id));
    //   }, 2000);
    // }

    sendMessage(replyData ? { ...data } : data);
    setReplyData(null);
  };

  // useEffect(() => {
  //   if (
  //     isUserMessageSent ||
  //     isMessageDeleted ||
  //     isMessageForwarded ||
  //     isUserMessagesDeleted ||
  //     isImageDeleted
  //   ) {
  //     dispatch(getChatUserConversations(chatUserDetails.id));
  //   }
  // }, [
  //   dispatch,
  //   isUserMessageSent,
  //   chatUserDetails,
  //   isMessageDeleted,
  //   isMessageForwarded,
  //   isUserMessagesDeleted,
  //   isImageDeleted,
  // ]);

  const onDeleteMessage = (messageId: string | number) => {
    // dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    // dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    // dispatch(toggleArchiveContact(chatUserDetails.id));
  };

  return selectedChat ? (
    <>
      <UserHead
        chatUserDetails={selectedChat}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        messages={messages}
        chatUserDetails={selectedChat}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
        isFetchingMessages={isFetchingMessages}
        loadMore={loadMore}
        hasMoreMessages={hasMoreMessages}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={selectedChat}
      />
    </>
  ) : (
    <></>
  );
};

export default Index;
