import { useEffect, useState } from "react";
import { Alert, Form } from "reactstrap";
import StartButtons from "./StartButtons";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";
import { MessagesTypes } from "../../../data/messages";
import { useProfile, useRedux } from "../../../hooks";
import { uploadFile } from "../../../utils/aws_s3";
import { toast } from "react-toastify";
import { SentMessage } from "../../../types/Messages";
import useMessages from "../../../hooks/useMessages";
import { setIsSendingMessage } from "../../../redux/actions";

interface IndexProps {
  onSend: (data: SentMessage) => void;
  replyData: null | any | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
}

const Index = ({
  onSend,
  replyData,
  onSetReplyData,
  chatUserDetails,
}: IndexProps) => {
  // console.log({ chatUserDetails });
  const { userProfile } = useProfile();
  const { dispatch } = useRedux();
  const { isSendingMessage } = useMessages();
  /*f
  more menu collapse
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
    setemojiArray(true);
  };

  /*
  disable send button
  */
  const [disabled, setDisabled] = useState<boolean>(true);

  /*
  input text
  */
  const [text, setText] = useState<null | string>("");
  const onChangeText = (value: string) => {
    // console.log("Text: ", value);
    setText(value);
  };

  /*
  images
  */
  const [images, setImages] = useState<Array<File>>([]);
  const [videos, setVideos] = useState<Array<File>>([]);
  const [audios, setAudios] = useState<Array<File>>([]);
  const onSelectImages = (images: Array<File>) => {
    if (images[0].type === "video/mp4" || images[0].type === "video/webm") {
      setVideos(images);
      setImages([]);
      setAudios([]);
      setFiles([]);
    } else {
      setImages(images);
      setVideos([]);
      setAudios([]);
      setFiles([]);
    }
  };
  const onSelectVideos = (files: Array<File>) => {
    setVideos(files);
    setImages([]);
    setAudios([]);
    setFiles([]);
  };
  const onSelectAudios = (files: Array<File>) => {
    setAudios(files);
    setImages([]);
    setVideos([]);
    setFiles([]);
  };
  /*
  files
  */
  const [files, setFiles] = useState<Array<File>>([]);
  const onSelectFiles = (files: Array<File>) => {
    console.log("file selected", files);
    setFiles(files);
    setImages([]);
    setAudios([]);
    setVideos([]);
  };

  useEffect(() => {
    if (
      text ||
      files.length ||
      images.length ||
      videos.length ||
      audios.length
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, images, files, videos, audios]);

  // emoji picker
  const [emojiArray, setemojiArray] = useState<any>("");
  const [emojiPicker, setemojiPicker] = useState<boolean>(false);
  const onEmojiClick = (event: any) => {
    setemojiArray([...emojiArray, event.emoji]);
    setText(text + event.emoji);
  };

  function hasUrl(text: string): boolean {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return urlRegex.test(text);
  }

  // Submit Message
  // const onSubmit = async () => {
  //   let data = {};
  //   if (images) {
  //     const fileLink = await uploadFile(images[0]);
  //     console.log({ fileLink });
  //     data = {
  //       // messaging_product: "whatsapp",
  //       // recipient_type: "individual",
  //       // to: chatUserDetails.phone ? chatUserDetails.phone : "0900000000000",
  //       type: "image",
  //       image: {
  //         id: "I4y5u3i3",
  //         link: fileLink,
  //         caption: text !== null ? text : "",
  //       },
  //     };
  //   } else if (videos) {
  //     const fileLink = await uploadFile(videos[0]);
  //     data = {
  //       // messaging_product: "whatsapp",
  //       // recipient_type: "individual",
  //       // to: chatUserDetails.phone ? chatUserDetails.phone : "0900000000000",
  //       type: "video",
  //       video: {
  //         id: "I4y5678i3",
  //         link: fileLink,
  //         caption: text !== null ? text : "",
  //       },
  //     };
  //   } else if (audios) {
  //     const fileLink = await uploadFile(audios[0]);
  //     data = {
  //       // messaging_product: "whatsapp",
  //       // recipient_type: "individual",
  //       // to: chatUserDetails.phone ? chatUserDetails.phone : "0900000000000",
  //       type: "audio",
  //       audio: {
  //         id: "I4y5678i3",
  //         link: fileLink,
  //         caption: text !== null ? text : "",
  //       },
  //     };
  //   } else if (files) {
  //     const fileLink = await uploadFile(files[0]);

  //     data = {
  //       // messaging_product: "whatsapp",
  //       // recipient_type: "individual",
  //       // to: chatUserDetails.phone ? chatUserDetails.phone : "0900000000000",
  //       type: "document",
  //       document: {
  //         id: "I4y5678i3",
  //         link: fileLink,
  //         caption: text !== null ? text : "",
  //         filename: files[0].name,
  //       },
  //     };
  //   } else if (text !== null) {
  //     data = {
  //       // messaging_product: "whatsapp",
  //       // recipient_type: "individual",
  //       // to: chatUserDetails.phone ? chatUserDetails.phone : "0900000000000",
  //       type: "text",
  //       text: {
  //         preview_url: hasUrl(text), // should be boolean but for now stays as false
  //         body: text,
  //       },
  //     };
  //   }

  //   if (images || files || text !== null) {
  //     setText("");
  //     setImages(null);
  //     setFiles(null);
  //     setemojiPicker(false);
  //     onSend(data);
  //   }
  // };

  const onSubmit = async () => {
    let data: Partial<SentMessage> = {
      isToWB: true,
      // isToWB: true,
      senderMobile: userProfile.mobileNumber,
      senderImage: userProfile.profileImage,
    };
    dispatch(setIsSendingMessage(true));

    if (images.length) {
      const fileLink = await uploadFile(images[0]);
      if (!fileLink) {
        toast.error("Error Sending File");
        dispatch(setIsSendingMessage(false));
        return;
      }

      data = {
        ...data,
        payload: {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: chatUserDetails.WAPhone
            ? chatUserDetails.WAPhone
            : "0900000000000",
          type: "image",
          image: {
            id: "I4y5u3i3",
            link: fileLink,
            caption: text !== null ? text : "",
            filesize: images[0].size,
          },
        },
      };
    } else if (videos.length) {
      const fileLink = await uploadFile(videos[0]);
      if (!fileLink) {
        toast.error("Error Sending File");
        dispatch(setIsSendingMessage(false));
        return;
      }

      data = {
        ...data,
        payload: {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: chatUserDetails.WAPhone
            ? chatUserDetails.WAPhone
            : "0900000000000",
          type: "video",
          video: {
            id: "I4y5678i3",
            link: fileLink,
            caption: text !== null ? text : "",
            filesize: videos[0].size,
          },
        },
      };
    } else if (audios.length) {
      const fileLink = await uploadFile(audios[0]);
      if (!fileLink) {
        toast.error("Error Sending File");
        dispatch(setIsSendingMessage(false));
        return;
      }

      data = {
        ...data,
        payload: {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: chatUserDetails.WAPhone
            ? chatUserDetails.WAPhone
            : "0900000000000",
          type: "audio",
          audio: {
            id: "I4y5678i3",
            link: fileLink,
            caption: text !== null ? text : "",
            filesize: audios[0].size,
          },
        },
      };
    } else if (files.length) {
      const fileLink = await uploadFile(files[0]);
      if (!fileLink) {
        toast.error("Error Sending File");
        dispatch(setIsSendingMessage(false));
        return;
      }

      data = {
        ...data,
        payload: {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: chatUserDetails.WAPhone
            ? chatUserDetails.WAPhone
            : "0900000000000",
          type: "document",
          document: {
            id: "I4y5678i3",
            link: fileLink,
            caption: text !== null ? text : "",
            filename: files[0].name,
            filesize: files[0].size,
          },
        },
      };
    } else if (text !== null) {
      data = {
        ...data,
        payload: {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: chatUserDetails.WAPhone
            ? chatUserDetails.WAPhone
            : "0900000000000",
          type: "text",
          text: {
            preview_url: hasUrl(text), // should be boolean but for now stays as false
            body: text,
          },
        },
      };
    }

    if (
      images.length ||
      files.length ||
      audios.length ||
      videos.length ||
      text !== null
    ) {
      setText("");
      setemojiPicker(false);
      onSend(data as SentMessage);
    }
    onClearMedia();
  };

  const onClearMedia = () => {
    setImages([]);
    setVideos([]);
    setAudios([]);
    setFiles([]);
  };

  return (
    <div className="chat-input-section p-3 p-lg-4">
      <Form
        id="chatinput-form"
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="row g-0 align-items-center">
          <div className="col-auto">
            <StartButtons
              onToggle={onToggle}
              onEmojiClick={onEmojiClick}
              setemojiPicker={setemojiPicker}
              emojiPicker={emojiPicker}
            />
          </div>
          <div className="col">
            <InputSection value={text} onChange={onChangeText} />
          </div>
          <div className="col-auto">
            <EndButtons
              onSubmit={onSubmit}
              disabled={disabled}
              isSending={isSendingMessage}
              onAudioRecordingCompleted={audio => setAudios([audio])}
            />
          </div>
        </div>
      </Form>
      {images.length || files.length || videos.length || audios.length ? (
        <Alert
          isOpen={true}
          toggle={onClearMedia}
          color="secondary"
          className="alert-dismiss-custom rounded-pill font-size-12 mb-1 selected-media mt-2"
          closeClassName="selected-media-close"
        >
          <p className="me-2 mb-0">
            {files.length > 0 && ` You have selected a file`}
            {images.length > 0 && ` You have selected an image`}
            {videos.length > 0 && ` You have selected a video`}
            {audios.length > 0 && ` You have selected an audio`}
            {/* {images && !files && ` You have selected ${images.length} images`}
            {files && !images && ` You have selected ${files.length} files`}
            {files &&
              images &&
              ` You have selected ${files.length} files & ${images.length} images.`} */}
          </p>
        </Alert>
      ) : null}

      <MoreMenu
        isOpen={isOpen}
        onSelectImages={onSelectImages}
        onSelectFiles={onSelectFiles}
        onSelectVideos={onSelectVideos}
        onSelectAudios={onSelectAudios}
        onToggle={onToggle}
      />

      <Reply
        reply={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </div>
  );
};

export default Index;
