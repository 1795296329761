import { useEffect, useRef, useState } from "react";
import { useRedux } from "../../hooks/index";
import { createSelector } from "reselect";
import AppSimpleBar from "../../components/AppSimpleBar";
import Loader from "../../components/Loader";
import Message from "./Message";
import { MessagesTypes } from "../../data/messages";
import ForwardModal from "../../components/ForwardModal";
import { forwardMessage, deleteImage } from "../../redux/actions";
import Day from "./Day";
import { firebaseTimestampToDate } from "../../utils";
import { Message as MessageType } from "../../types/Messages";
import { Spinner } from "reactstrap";

interface ConversationProps {
  messages: MessageType[];
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | any | undefined) => void;
  isChannel: boolean;
  isFetchingMessages: boolean;
  hasMoreMessages: boolean;
  loadMore: () => Promise<void>;
}
const Conversation = ({
  chatUserDetails,
  messages,
  onDelete,
  onSetReplyData,
  isChannel,
  isFetchingMessages,
  hasMoreMessages,
  loadMore,
}: ConversationProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const pauseScrollListenerRef = useRef(true);
  const messagesContainerRef = useRef<HTMLElement | null>(null);
  const messagesListRef = useRef<(HTMLElement | null)[]>([]);
  const firstDataLoaded = useRef(false);
  const messagesCount = useRef(0);
  const paginationTriggerPoint = 400;
  const scrollPositionRef = useRef({
    previousHeight: 0,
    previousScrollTop: 0,
  });

  const errorData = createSelector(
    (state: any) => state.Chats,
    state => ({
      isMessageForwarded: state.isMessageForwarded,
    }),
  );
  // Inside your component
  const { isMessageForwarded } = useAppSelector(errorData);

  function extractUrls(text: string) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = text.match(urlRegex);
    return urls || [];
  }

  // scroll listener to fetch more messages
  const handleScroll = async (element: HTMLElement | null) => {
    if (!element) return;

    // save scroll position to be restored after loading more messages
    scrollPositionRef.current = {
      previousHeight: element.scrollHeight,
      previousScrollTop: element.scrollTop,
    };

    // to prevent loading more when scrolling to bottom
    if (
      element.scrollTop > paginationTriggerPoint &&
      pauseScrollListenerRef.current
    ) {
      pauseScrollListenerRef.current = false;
      return;
    }

    if (
      element.scrollTop <= paginationTriggerPoint &&
      !isFetchingMessages &&
      !pauseScrollListenerRef.current &&
      hasMoreMessages
    ) {
      await loadMore();
    }
  };

  useEffect(() => {
    if (messages.length > 0 && messagesContainerRef.current) {
      if (
        firstDataLoaded.current &&
        scrollPositionRef.current.previousHeight > 0
      ) {
        // restore scroll position after new messages are added so that the user has smooth experience without unwanted scroll
        const newScrollTop =
          messagesContainerRef.current.scrollHeight -
          scrollPositionRef.current.previousHeight +
          scrollPositionRef.current.previousScrollTop;

        messagesContainerRef.current.scrollTop = newScrollTop;

        pauseScrollListenerRef.current = true;
      } else {
        // if this is first load, scroll to the bottom of the page
        messagesListRef.current[messages.length - 1]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        firstDataLoaded.current = true;
      }
      messagesCount.current = messages.length;
    }
  }, [messages]);

  /*
  forward message
  */
  const [forwardData, setForwardData] = useState<
    null | MessagesTypes | undefined
  >();
  const [isOpenForward, setIsOpenForward] = useState<boolean>(false);
  const onOpenForward = (message: MessagesTypes) => {
    setForwardData(message);
    setIsOpenForward(true);
  };
  const onCloseForward = () => {
    setIsOpenForward(false);
  };

  const onForwardMessage = (data: any) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
    dispatch(forwardMessage(params));
  };
  useEffect(() => {
    if (isMessageForwarded) {
      setIsOpenForward(false);
    }
  }, [isMessageForwarded]);

  // useEffect(() => {
  // createChat();
  // }, [chatUserDetails]);

  /*
  image delete
  */
  const onDeleteImage = (
    messageId: string | number,
    imageId: string | number,
  ) => {
    dispatch(deleteImage(chatUserDetails.id, messageId, imageId));
  };

  const MessagesList = () => {
    return (messages || []).map((message, index: number) => {
      if (!message && Object.keys(message).length === 0) return;

      // const isFromMe = message.entry
      //   ? message.entry[0].id == user_id
      //   : message.senderMobile == userProfile.mobileNumber;
      let isFromMe: boolean;
      let validMessage: any;
      let senderId: string;

      if ("entry" in message) {
        // sent message
        isFromMe = false;
        senderId = message.entry[0]?.id;
        validMessage = {
          ...message.entry[0]?.changes[0]?.value?.messages?.[0],
          time: message.timestamp,
        };
      } else {
        // receivedMessage
        isFromMe = true;
        senderId = message.senderId;
        validMessage = message;
      }

      const msg_id = message.id;

      let showDateDivider = index === 0 ? true : false;
      const date = message.timestamp
        ? firebaseTimestampToDate(message.timestamp).toDateString()
        : null;
      const prevMessageTime = messages[index - 1]?.timestamp;
      if (prevMessageTime) {
        const prevDate = firebaseTimestampToDate(
          messages[index - 1]?.timestamp,
        ).toDateString();
        if (date !== prevDate) showDateDivider = true;
      }

      return (
        <>
          {showDateDivider && date && <Day date={date} />}
          <Message
            index={index}
            messagesListRef={messagesListRef}
            key={message.id}
            msg_id={msg_id}
            sender_id={senderId}
            isLeagay={!isFromMe}
            message={validMessage}
            chatUserDetails={chatUserDetails}
            onDelete={onDelete}
            onSetReplyData={onSetReplyData}
            isFromMe={isFromMe}
            onOpenForward={onOpenForward}
            isChannel={isChannel}
            onDeleteImage={onDeleteImage}
          />
        </>
      );
    });
  };

  return (
    <div
      ref={el => (messagesContainerRef.current = el)}
      id="chat-conversation"
      className="chat-conversation p-3 p-lg-4 positin-relative messages-container"
      onScroll={_ => handleScroll(messagesContainerRef.current)}
    >
      <ul
        className="list-unstyled chat-conversation-list"
        id="chat-conversation-list"
      >
        {isFetchingMessages && (
          <div className="d-flex justify-content-center align-items-center w-100 mb-2">
            <Spinner color="primary" />
          </div>
        )}
        {!hasMoreMessages && messages.length > 0 && (
          <Day date="Start of Chat" />
        )}
        <MessagesList />
      </ul>
      {isOpenForward && (
        <ForwardModal
          isOpen={isOpenForward}
          onClose={onCloseForward}
          forwardData={forwardData}
          chatUserDetails={chatUserDetails}
          onForward={onForwardMessage}
        />
      )}
    </div>
  );
};

export default Conversation;
