import React from "react";
import "../../assets/scss/custom/pages/_categories.scss";
import { useTranslation } from "react-i18next";

// Define category type
type Category = {
  id: number;
  name: string;
  image: string;
  link: string;
};

const Categories = ({
  Category,
  sellerData,
  setInitialCategory,
}: {
  Category: any;
  sellerData: any;
  setInitialCategory: any;
}) => {
  // console.log("Category ",Category)
  // console.log("sellerData",sellerData)
  const { t } = useTranslation();
  return (
    <div className="category-container">
      <div id="container-categories">
       

        <div className="category-grid">
          {Category &&
            Category?.map((category: any, index: any) => (
              <div
                key={index + category?.en?.title}
                // href={`http://localhost:3000/store/BBAStore?categoryId=${index + 1}`}
                // href="#sticky-header"
                className="category-card"
                onClick={() => setInitialCategory(category)}
              >
                <img
                  src={category?.en?.image}
                  alt={category?.en?.title}
                  loading="lazy"
                  decoding="async"
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
