import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "../../assets/scss/custom/pages/_check-out.scss";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PaymentFailed from "./PaymentFailed";
import PaymentSuccess from "./PaymentSuccess";
import PaymentSection from "./PaymentSection";
import axios from "axios";
import { useCart } from "./contexts/CartContext";

const Loader = () => (
  <div className="loader-overlay">
    <div className="loader"></div>
  </div>
);

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  cart: any;
  setCart: any;
  sellerData: any;
  verifiedUser: any;
  WAPhone: string;
  chatId: string;
  cartIdP: string;
  cartStatus: string;
}

const CheckoutModal: React.FC<CheckoutModalProps> = ({
  isOpen,
  onClose,
  cart,
  setCart,
  sellerData,
  verifiedUser,
  WAPhone,
  chatId,
  cartIdP,
  cartStatus
}) => {
  const vendorId = sellerData?.id;

  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(2);
  const [shippingAddress, setShippingAddress] = useState<string>("");
  const [addresses, setAddresses] = useState<any[]>([]);
  const [cartIdCheck, setCartIdCheck] = useState<string>(cartIdP)
  const [chatIdCheck, setChatIdCheck] = useState<string>(chatId)
  const [key, setKey] = useState(0); // Key to force re-render
  const {cartId,chatId:chatIDS} = useCart()

  useEffect(() => {
    if(!cartIdP){
      setCartIdCheck(cartId)
    }
  }, [cartId])

  useEffect(() => {
    if(!chatId){
      setChatIdCheck(chatIDS)
    }
    console.log("chatId && chatIdCheck.length === 0",chatId && chatIdCheck.length === 0)
    if(chatId && chatIdCheck.length === 0){
      setChatIdCheck(chatId)
    }
  }, [chatIDS,chatId])

  const handlePaymentFailure = () => {
    setKey((prevKey) => prevKey + 1); // Increment key to trigger re-render
  };
  const [newAddress, setNewAddress] = useState({ name: "", address: "" });
  const [isAdding, setIsAdding] = useState(false);
  const [addLocationLoading, setAddLocationLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState("");
  const [addressLoading, setAddressLoading] = useState(false);
  const {carts} = useCart();

  useEffect(() => {
    if (verifiedUser) {
      // setCurrentStep(2);
      if(cartStatus && cartStatus === "paid"){
        setCurrentStep(7);
      } else {
        setCurrentStep(2);
      }
    }
  }, [verifiedUser]);

  const fetchLocations = async () => {
    // console.log("WAPhone",WAPhone)
    if (!WAPhone) return;

    // setLoading(true);
    try {
      const q = query(
        collection(db, "Locations"),
        where("WAPhone", "==", WAPhone)
      );
      const querySnapshot = await getDocs(q);

      const locations: any[] = [];
      querySnapshot.forEach((doc) => {
        locations.push({ id: doc.id, ...doc.data() });
      });

      // setSavedLocations(locations);
      setAddresses(locations);
    } catch (error) {
      console.error("Error fetching locations:", error);
      // alert("Failed to fetch locations.");
      toast.error("Failed to fetch locations.");
    }
    // finally {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    fetchLocations();
  }, [currentStep]);

  // console.log("address ",addresses)

  const saveLocation = async (
    WAPhone: string,
    name: string,
    address: string
  ) => {
    console.log("WAPhone",WAPhone,address,name)
    if (!WAPhone || !address || !name) {
      // alert("Please provide both name and address.");
      toast.error("Please provide both name and address.");
      return;
    }

    setAddLocationLoading(true);
    try {
      const locationData = {
        WAPhone,
        name,
        address,
      };
      await setDoc(doc(collection(db, "Locations")), locationData);
      // alert("Location added successfully!");
      toast.success(`${t("addressModal.locationAddedSuccess")}`);

      fetchLocations();
    } catch (error) {
      console.error("Error saving location:", error);
      // alert("Failed to save location.");
      toast.error(`${t("addressModal.locationSaveError")}`);
    } finally {
      // setLoading(false);
      setAddLocationLoading(false);
      setIsAdding(false);
      setNewAddress({ name: "", address: "" }); // Reset form fields
    }
  };

  // console.log("shippingAddress ", shippingAddress);

  async function addCartProducts(cartItems: any) {
    console.log("cartItem", cartItems);
    console.log("s ", sellerData?.id,"cartIdCheck : ",cartIdCheck,shippingAddress,chatIdCheck)
    console.log(" chatId ",chatId," chatIdc ",chatIdCheck)
    if (!sellerData?.id) return;
    if (!cartIdCheck) {
      console.error("Cart ID is missing.");
      toast.error("Invalid cart ID.");
      return;
    }
    if (!cartItems) {
      console.error("Cart items are missing.");
      toast.error("Cart items cannot be empty.");
      return;
    }
    if (!shippingAddress) {
      console.error("Shipping address is missing.");
      toast.error("Shipping address is required.");
      return;
    }
    setLoading(true);

    const cartDBID = cartIdCheck

    try {
      // Define the document reference
      const cartDocPath = `/Vendors/${sellerData?.id}/Chats/${chatIdCheck}/Carts/${cartDBID}`;
      console.log("cartDocPath",cartDocPath)
      const cartDocRef = doc(db, cartDocPath);

      // Data to update
      const updateData = {
        products: cartItems,
        WAPhone: WAPhone,
        shippingAddress: shippingAddress,
      };

      // Update the document
      await updateDoc(cartDocRef, updateData);
      console.log("Cart details updated successfully.");
      toast.success("Cart updated successfully!");

      setLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating cart details:", {
        error,
        cartIdCheck,
        updateData: { cartItems, WAPhone, shippingAddress },
      });
      toast.error("Error updating cart details. Please try again.");
      setLoading(false);
      return false;
    }
  }

  const handleShipping = async () => {
    if (shippingAddress === "") {
      alert("Please select shipping");
    } else {
      // handleContinue();
      const success = await addCartProducts(cart);
      if (success) {
        handleContinue();
      }
    }
  };
  // console.log("shippingAddress ",shippingAddress)

  const calculateTotals = () => {
    const subtotal = carts.reduce(
      // price 604.06 BHD
      (acc, item) => acc + item.quantity * Number(item?.price?.replace(" BHD", "")),
      0
    );
    // console.log("subtotal",subtotal,carts)
    const shipping = 15.0; // Fixed shipping cost
    const taxes = subtotal * 0.0462; // Example tax rate 4.62%
    const totalBeforeDiscount = subtotal + shipping + taxes;
    const total = totalBeforeDiscount - 0;
    // console.log("total",total)

    return { subtotal, shipping, taxes, total, totalBeforeDiscount };
  };

  const { subtotal, shipping, taxes, total, totalBeforeDiscount } =
    calculateTotals();

  const handleContinue = () => setCurrentStep((prev) => prev + 1);

  // Get the user's location
  const getUserLocation = () => {
    setAddressLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          // setLocation((prev: any) => ({ ...prev, latitude, longitude }));
          getAddressFromCoords(latitude, longitude);
        },
        (error) => {
          toast.error("Error getting location");
          console.error(error);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  // Reverse Geocode using OpenStreetMap Nominatim API
  const getAddressFromCoords = async (latitude: any, longitude: any) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

    try {
      const response = await axios.get(url, {
        headers: { "User-Agent": "Location-App" },
      });

      // @ts-ignore
      if (response && response.display_name) {
        // @ts-ignore
        const address = response.display_name;
        // setLocation((prev:any) => ({ ...prev, address }));
        // setAddress(address);
        setNewAddress((prev) => ({ ...prev, address }));
      } else {
      }
    } catch (err) {
      console.error(err);
    } finally {
      setAddressLoading(false);
    }
  };

  useEffect(() => {
    // getUserLocation();
  
      getUserLocation();
    
  }, [currentStep]);

  return (
    <div className={`modal checkout-modal ${isOpen ? "is-active" : ""}`}>
      {isLoading && <Loader />} {/* Loader displayed during loading */}
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className="checkout-container">
          {/* Modal Header */}
          <div className="cart-header">
            <div className="header-inner">
              <h2 id="checkout-step-title">
                {/* {currentStep === 1 && `Verify Order`} */}
                {currentStep === 2 && `${t("checkout.confirmOrder")}`}
                {currentStep === 3 && `${t("checkout.shipping")}`}
                {currentStep === 4 && `${t("checkout.payment")}`}
                {currentStep === 5 && `${t("checkout.thankYou")}`}
                {currentStep === 6 && `${t("checkout.paymentFailure")}`}
                {currentStep === 7 && "Order Already Paid"}
              </h2>
              <button
                className="button is-light"
                onClick={() => {
                  onClose();
                  setCurrentStep(2);
                }}
              >
                <FaTimes />
              </button>
            </div>
          </div>

          {/* {currentStep === 1 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2rem",
                }}
              >
                <label>Enter your phone number</label>
                <input
                  type="text"
                  className="input"
                  placeholder="Phone number"
                  value={WAPhoneNumber}
                  onChange={(e) => {
                    setWAPhoneNumber(e.target.value);
                  }}
                />

                <div>
                  <button
                    onClick={() => {
                      window.open(
                        // `wa.me/${sellerData?.WBPhone}`
                        `https://wa.me/${sellerData?.WBPhone}`
                      );
                    }}
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      padding: "0.5rem 1rem",
                      borderRadius: "5px",
                      backgroundColor: "#25D366",
                      color: "#fff",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Verify
                  </button>
                </div>

                <div>
                  <button
                    className="button is-solid primary-button raised"
                    onClick={handleContinue}
                    style={{
                      marginTop: "1rem",
                      marginBottom: "1rem",
                      padding: "0.5rem 1rem",
                      borderRadius: "5px",
                      backgroundColor: "#25D366",
                      color: "#fff",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {t("checkout.continue")}
                  </button>
                </div>
              </div>
            </>
          )} */}

          {/* Step 1: Order Confirmation */}
          {currentStep === 2 && (
            <div className="columns">
              <div className="column is-8">
                <div className="checkout-section">
                  <div className="flex-table">
                    <div className="flex-table-header">
                      <span>{t("checkout.product")}</span>
                      <span>{t("checkout.quantity")}</span>
                      <span>{t("checkout.price")}</span>
                      {/* <span>Discount</span> */}
                      <span>{t("checkout.total")}</span>
                    </div>
                    {carts &&
                      carts?.length > 0 &&
                      carts?.map((item: any, index: any) => (
                        <div className="flex-table-items"
                        key={index}
                        >
                          <div className="product">
                            <img src={item?.image_link} alt="Cool Shirt" />
                            <span>
                              {item?.title
                                ? item?.title?.slice(0, 12) + "..."
                                : "Product Name"}
                            </span>
                          </div>
                          <div className="quantity">{item?.quantity || 1}</div>
                          <div className="price">
                            {item?.price || "29.00"} 
                          </div>
                          {/* <div className="discount">$0</div> */}
                          <div className="total">
                            {item?.price
                              ? Number(item?.price?.replace(" BHD", "")) * item?.quantity
                              : "29.00"}{" "}
                            
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="column is-4">
                <div className="cart-summary">
                  <h3>{t("cartModal.orderSummary")}</h3>
                  <div className="summary-card">
                    <div className="order-line">
                      <span>{t("checkout.subtotal")}</span>
                      <span>{subtotal.toFixed(2)} BHD</span>
                    </div>
                    <div className="order-line">
                      <span>{t("checkout.shippingCost")}</span>
                      <span>{shipping.toFixed(2)} BHD</span>
                    </div>
                    <div className="order-line">
                      <span>{t("checkout.taxes")}</span>
                      <span>{taxes.toFixed(2)} BHD</span>
                    </div>
                    {/* <div className="order-line">
                      <span>Discount</span>
                      <span>{discount.toFixed(2)} BHD</span>
                    </div> */}
                    <div className="order-line is-total">
                      <span>{t("checkout.total")}</span>
                      <span>{total.toFixed(2)} BHD</span>
                    </div>
                    <button
                      className="button is-solid primary-button raised"
                      onClick={handleContinue}
                    >
                      {t("checkout.continue")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Step 2: Add Address */}

          {currentStep === 3 && (
            <div className="address-section">
              <h3>{t("checkout.shippingAddress")}</h3>
              <div className="address-list">
                {addresses.map((address, index) => (
                  <div
                    key={index}
                    //className="address-item"
                    className={`address-item ${shippingAddress === address?.address ? "selected" : ""}`}
                  >
                    <label>
                      <input
                        type="radio"
                        name="shippingAddress"
                        value={address}
                        checked={shippingAddress === address?.address}
                        onChange={() => setShippingAddress(address?.address)}
                      />

                      <span className="">{address?.name}</span>
                      {address?.address}
                    </label>
                  </div>
                ))}
              </div>
              {/* Add New Address Section */}
              {isAdding ? (
                <div className="new-address-form">
                  <h4>
                    {/* {t("checkout.addNewAddress")} */}
                    Add New Address
                  </h4>
                  <input
                    type="text"
                    placeholder="Name"
                    value={newAddress.name}
                    onChange={(e) =>
                      setNewAddress((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                  <input
                    type="text"
                    placeholder={addressLoading ? "Loading..." : "Address"}
                    value={newAddress.address}
                    onChange={(e) =>
                      setNewAddress((prev) => ({
                        ...prev,
                        address: e.target.value,
                      }))
                    }
                    disabled={addressLoading}
                  />
                  <button
                    className="button is-solid bg-primary"
                    onClick={() => {
                      if (newAddress.name && newAddress.address) {
                        saveLocation(
                          WAPhone,
                          newAddress.name,
                          newAddress.address
                        );
                      }
                    }}
                  >
                    {/* {t("checkout.saveAddress")} */}
                    Save Address
                  </button>
                  <button
                    className="button is-light"
                    onClick={() => setIsAdding(false)}
                  >
                    {/* {t("checkout.cancel")} */}
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  className="button is-light"
                  onClick={() => setIsAdding(true)}
                >
                  {/* {t("checkout.addAddress")} */}
                  {addLocationLoading ? "Adding..." : "Add Address"}
                </button>
              )}

              <button
                className="button is-solid bg-primary raised"
                // onClick={handleContinue}
                disabled={shippingAddress === "" || loading}
                onClick={handleShipping}
              >
                {loading
                  ? `${t("checkout.shippingInProgress")}`
                  : `${t("checkout.continue")}`}
              </button>
            </div>
          )}

          {/* Step 3: Payment Section */}
          {currentStep === 4 && (
            <PaymentSection
            key={key} // Pass key to force re-render
            onPaymentFailure={handlePaymentFailure} // Callback for payment failure
              WAPhone={WAPhone}
              cart={cart}
              cartId={cartIdCheck}
              vendorId={vendorId}
              chatId={chatIdCheck}
              total={total}
              shippingAddress={shippingAddress}
              setCurrentStep={setCurrentStep}
            />
          )}

          {/* Step 3: Thank You Page */}
          {currentStep === 5 && (
            <PaymentSuccess
              subtotal={subtotal}
              shipping={shipping}
              taxes={taxes}
              total={total}
            />
          )}

          {currentStep === 6 && (
            <PaymentFailed
              shipping={shipping}
              subtotal={subtotal}
              taxes={taxes}
              total={total}
            />
          )}

          {/* step 7: Order already placed */}

          {
            currentStep === 7 && (
              <div className="order-already-placed">
                <h3
                style={{
                  textAlign: "center",
                  color: "#4eac6d",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  minHeight: "200px",
                  // textTransform: "uppercase"
                }}
                >Your order has already been placed and <br/> We are working on your order</h3>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default CheckoutModal;
