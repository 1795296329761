import React, { useEffect, useState } from "react";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  Button,
} from "reactstrap";
import "../../assets/scss/custom/pages/_navbar.scss";
import "../../assets/scss/custom/pages/_cart_modal.scss";
import { GoSearch } from "react-icons/go";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { IoBagHandleSharp } from "react-icons/io5";
import { FaGlobeEurope } from "react-icons/fa";
import { useLanguage } from "./contexts/LanguageContext";
import { useTranslation } from "react-i18next";
import { FaLocationDot } from "react-icons/fa6";
import { FaLocationArrow } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { FaBars } from "react-icons/fa";
import CheckoutModal from "./CheckoutModal";
import { toast } from "react-toastify";
import { MdOutlineClose } from "react-icons/md";
import AddressModal from "./AddressModal";
import CartModal from "./CartModal";
import { db } from "../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useSearch } from "./contexts/SearchContext";
import axios from "axios";
import { useCart } from "./contexts/CartContext";

const Navbar = ({
  cart,
  setCart,
  sellerData,
  verifiedUser,
  WAPhone,
  chatId,
  cartId,
  vendorId,
  cartStatus,
  setWAPhone
}: {
  cart: any[];
  setCart: any;
  sellerData: any;
  verifiedUser: any;
  WAPhone: string;
  chatId: string;
  cartId: string;
  vendorId: string;
  cartStatus: string;
  setWAPhone: any;
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobiledropdownOpen, setMobileDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  // const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  // console.log("results ", results);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [language, setLanguage] = useState("English");
  const { language, setLanguage, handleLanguageChange } = useLanguage();
  // console.log("language ", language, " direction ", direction);
  // console.log("dropdownOpen ", dropdownOpen);
  const { search, setSearch, setCategories, setProducts } = useSearch();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setMobileMenuOpen(false);
  };
  const toggleCart = () => {
    setCartOpen(!cartOpen);
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };
  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);

  const removeCartItem = (id: number) => {
    setCart(cart.filter((item: any) => item.id !== id));
  };

  const addCartItem = (id: number) => {
    const itemIndex = cart.findIndex((item: any) => item.id === id);
    const newCartItems = [...cart];
    newCartItems[itemIndex].quantity += 1;
    setCart(newCartItems);
  };

  const MinusCartItem = (id: number) => {
    const itemIndex = cart.findIndex((item: any) => item.id === id);
    const newCartItems = [...cart];
    newCartItems[itemIndex].quantity -= 1;

    if (newCartItems[itemIndex].quantity === 0) {
      //  remove item from cart
      newCartItems.splice(itemIndex, 1);
      toast.error(`${t("cart.itemRemoved")}`);
    }

    setCart(newCartItems);
  };

  const handleSearch = async (term: any) => {
    // console.log("searching for ", term);
    if (!term) {
      setResults([]);
      return;
    }
    // console.log("searching for p ", term);

    setLoading(true);
    const searchResults: any = await globalSearch(term,vendorId);
    // console.log("searchResults ", searchResults);
    setCategories(searchResults.categories);
    setProducts(searchResults.products);

    // setResults(searchResults);
    setLoading(false);
  };

  const handleInputChange = (e: any) => {
    const term = e.target.value;
    // console.log("term ", term);
    // setSearchQuery(term);
    setSearch(term);
    handleSearch(term);
  };

  // console.log("seller ", sellerData);

  useEffect(() => {
   if(verifiedUser && cart?.length > 0){
    setIsModalOpen(true);
   }
  }, [verifiedUser]);

  const {WAPhone:Phone} = useCart();

  useEffect(() => {
     if(Phone){
      setWAPhone(Phone)
     }
  }, [Phone]);

  return (
    <div className="navbar-container">
      <Nav className="navbar">
        <NavItem className="logo">
          <NavLink href="#">{t("navbar.brandLogo")}</NavLink>
        </NavItem>

        <NavItem className="delivery">
          <span className="delivery-address" onClick={toggleModal}>
            {/* {t("navbar.deliveryTo")}{" "}
            <strong>{t("navbar.selectAddress")} </strong>{" "}
            <IoMdArrowDropdownCircle size={22} onClick={toggleModal} /> */}
            <FaLocationDot size={24} />
          </span>
        </NavItem>

        {/* Updated Search Section */}
        <NavItem className="search">
          <section className="search-container">
            <div className="search-wrapper">
              <div className="search-icon">
                <GoSearch size={18} />
              </div>
              <input
                type="text"
                placeholder={t("navbar.searchPlaceholder")}
                value={search}
                onChange={(e) => {
                  // console.log("e ", e.target.value);
                  handleInputChange(e);
                }}
                className="search-input"
              />
            </div>
          </section>
        </NavItem>

        <NavItem className="cart">
          <div onClick={toggleCart} className="cart-link">
            {
              cart.length > 0 && (<div className="cart-dot" />)
            }
            <FiShoppingCart size={18} />
          </div>
        </NavItem>

        <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle nav caret>
            <FaGlobeEurope className="globe-icon" size={18} />
            {language === "English" ? "English" : "عربى"}
            {/* {language} */}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                // setLanguage("English");
                // localStorage.setItem("appLanguage", "English");
                handleLanguageChange("English");
                setDropdownOpen(false);
              }}
            >
              English
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                // setLanguage("Arabic");
                // localStorage.setItem("appLanguage", "Arabic");
                handleLanguageChange("Arabic");
                setDropdownOpen(false);
              }}
            >
              عربى
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {/* <NavItem
          className="register"
          onClick={() => {
            window.location.href = `http://wa.me/${sellerData?.WBPhone}`;
          }}
        >
          <Button color="primary">{t("navbar.register")}</Button>
        </NavItem> */}

        <NavItem className="language-selector-mobile">
          <div
            className="language-selector"
            onClick={(e) => {
              e.stopPropagation();
              setMobileDropdownOpen(true);
            }}
          >
            <div className="language-toggle">
              <FaGlobeEurope size={18} />
              <span className="language-text">
                {language === "English" ? "E" : "A"}
              </span>
              {/* <IoMdArrowDropdownCircle size={18} /> */}
            </div>

            {mobiledropdownOpen && (
              <ul className="dropdown-menu">
                <li
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setLanguage("English");
                    // localStorage.setItem("appLanguage", "English");
                    handleLanguageChange("English");
                    setMobileDropdownOpen(false);
                  }}
                >
                  English
                </li>
                <li
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setLanguage("Arabic");
                    // localStorage.setItem("appLanguage", "Arabic");
                    handleLanguageChange("Arabic");
                    setMobileDropdownOpen(false);
                  }}
                >
                  عربى
                </li>
              </ul>
            )}
          </div>
        </NavItem>

        {/* <NavItem className="cart-mobile">
          <div className="cart-m">
            <span onClick={toggleCart} className="cart-link-m">
              <IoBagHandleSharp size={18} />
              {cart.length > 0 ? (
                <span className="cart-count-m">{cart.length}</span>
              ) : null}
            </span>
          </div>
        </NavItem> */}

        <NavItem className="mobile-menu-toggle">
          <FaBars size={22} onClick={toggleMobileMenu} />
        </NavItem>
      </Nav>

      {/* Mobile Menu Overlay */}
      <div className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-content">
          <div className="close-menu-btn" onClick={toggleMobileMenu}>
            <MdOutlineClose />
          </div>

          {/* Brand Logo */}
          <div className="logo">
            <a href="#" className="brand-logo">
              {t("navbar.brandLogo")}
            </a>
          </div>

          {/* Delivery Address */}
          {/* <div className="delivery">
            <span className="delivery-address" onClick={toggleModal}>
              {t("navbar.selectAddress")}{" "}
            </span>
          </div> */}

          {/* Search Bar */}
          {/* <div className="search">
            <div className="search-container">
              <div className="search-wrapper">
                <GoSearch size={18} className="search-icon" />
                <input
                  type="text"
                  placeholder={t("navbar.searchPlaceholder")}
                  value={search}
                  onChange={(e) => {
                    // console.log("e ", e.target.value);
                    handleInputChange(e);
                  }}
                  className="search-input"
                />
              </div>
            </div>
          </div> */}

          {/* Cart Link */}
          {/* <div className="cart">
            <span onClick={toggleCart} className="cart-link">
              {cart.length > 0 ? (
                <span className="cart-count">{cart.length}</span>
              ) : (
                <IoBagHandleSharp size={18} />
              )}
              My Cart
              {t("navbar.myCart")}
            </span>
          </div> */}

          {/* Language Selector */}
          {/* <div
            className="language-selector"
            onClick={(e) => {
              e.stopPropagation();
              setMobileDropdownOpen(true);
            }}
          >
            <div className="language-toggle">
              <FaGlobeEurope size={18} />
              <span className="language-text">
                {language === "English" ? "English" : "عربى"}
              </span>
              <IoMdArrowDropdownCircle size={18} />
            </div>

            {mobiledropdownOpen && (
              <ul className="dropdown-menu">
                <li
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setLanguage("English");
                    // localStorage.setItem("appLanguage", "English");
                    handleLanguageChange("English");
                    setMobileDropdownOpen(false);
                  }}
                >
                  English
                </li>
                <li
                  className="dropdown-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setLanguage("Arabic");
                    // localStorage.setItem("appLanguage", "Arabic");
                    handleLanguageChange("Arabic");
                    setMobileDropdownOpen(false);
                  }}
                >
                  عربى
                </li>
              </ul>
            )}
          </div> */}

          {/* Register Button */}
          {/* <div
            className="register"
            onClick={() => {
              window.location.href = `http://wa.me/${sellerData?.WBPhone}`;
            }}
          >
            <button className="btn-primary">{t("navbar.register")}</button>
          </div> */}
        </div>
      </div>

      {/* Updated Modal for Address Selection */}

      <AddressModal modalOpen={modalOpen} toggleModal={toggleModal} 
      WAPhone={WAPhone}
      />

      {/* Cart Modal */}
      <div
        className={`cart-overlay ${cartOpen ? "open" : ""}`}
        onClick={toggleCart}
      />

      <CartModal
        cartOpen={cartOpen}
        cartItems={cart} 
        toggleCart={toggleCart}
        removeCartItem={MinusCartItem}
        checkout={() => {
          setIsModalOpen(true);
          setCartOpen(false); 
        }}
        loading={loading}
        addCartItem={addCartItem}
        sellerData={sellerData}
      />
      <CheckoutModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cart={cart}
        setCart={setCart}
        sellerData={sellerData}
        verifiedUser={verifiedUser} 
        cartStatus={cartStatus}
        WAPhone={WAPhone}
        chatId={chatId}
        cartIdP={cartId}

      />
      <section className="search-container-mobile">
        <div className="search-wrapper">
          <div className="search-icon">
            <GoSearch size={18} />
          </div>
          <input
            type="text"
            placeholder={t("navbar.searchPlaceholder")}
            value={search}
            onChange={(e) => {
              // console.log("e ", e.target.value);
              handleInputChange(e);
            }}
            className="search-input"
          />
        </div>
      </section>
    </div>
  );
};

export default Navbar;

async function globalSearch(searchTerm: any,vendorId: any) {
  // console.log("Searching for:", searchTerm);
  try {
    // Normalize the search term for consistency
    const normalizedSearchTerm = searchTerm.toLowerCase();
    // console.log("Normalized Search Term:", normalizedSearchTerm);
    const id = vendorId || "BYNSyUHcPVPvjqPqT64YwkDck6w2";

    const docRef = doc(db, "Vendors", id); // Reference to the specific vendor document
    const docSnap = await getDoc(docRef);
    let categories = [];

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.Categories) {
        // Filter categories based on title
        const filteredCategories = data.Categories.filter(
          (category: any) =>
            category?.en?.title &&
            category.en.title.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // console.log("Search Results:", filteredCategories);
        categories = filteredCategories;
      }
    }

    const inventoryRef = collection(
      db,
      `Vendors/${id}/Inventory`
    );
    // const normalizedSearchTerm = searchTerm.toLowerCase();

    const querySnapshot = await getDocs(inventoryRef);

    const inventoryItems = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Filter locally by name or category
    const filteredInventory = inventoryItems.filter((item: any) =>
      // item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.category.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // console.log("Search Products Results:", filteredInventory);

    // Combine Results
    // const results = [...filteredInventory];
    const results = {
      categories: categories,
      products: filteredInventory,
    };
    return results;
  } catch (error) {
    console.error("Error performing global search:", error);
    return [];
  }
}
