import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
    collection,
    getDocs,
    deleteDoc,
    doc,
    query,
    orderBy,
    startAfter,
    limit,
    updateDoc,
} from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import '../../assets/scss/custom/pages/page.css'

interface Item {
    id: string;
    availability: string;
    brand: string;
    category: string;
    description: string;
    image_link: string;
    title: string;
    sub_catalog: string;
    price: string;
    condition: string;
    link: string;
    item_group_id: string;
}

export default function Inventory() {
    const [items, setItems] = useState<Item[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const [lastVisible, setLastVisible] = useState<any>(null);
    const [hasMore, setHasMore] = useState(true);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<string | null>(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const docId = localStorage.getItem("docId");
                if (!docId) {
                    console.error("Document ID not found in localStorage.");
                    return;
                }

                const userItemsCollection = collection(db, `Vendors/${docId}/Inventory`);
                let q = query(userItemsCollection, orderBy("title"), limit(itemsPerPage));

                if (lastVisible) {
                    q = query(userItemsCollection, orderBy("title"), startAfter(lastVisible), limit(itemsPerPage));
                }

                const querySnapshot = await getDocs(q);
                const itemsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...(doc.data() as Omit<Item, "id">),
                }));
                if (itemsData.length < itemsPerPage) {
                    setHasMore(false);
                }
                setItems(itemsData);
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            } catch (error) {
                console.error("Error fetching items: ", error);
            }
            finally {
                setIsLoading(false); // Set loading to false after data is fetched
            }
        };

        fetchItems();
    }, [currentPage]);

    const handleDeleteButton = (id: string) => {
        setItemToDelete(id);
        setShowConfirmModal(true);
    };

    const handleDeleteItem = async () => {
        try {
            const docId = localStorage.getItem("docId");
            if (!docId || !itemToDelete) {
                console.error("Document ID or Item ID not found.");
                return;
            }
            await deleteDoc(doc(db, `Vendors/${docId}/Inventory`, itemToDelete));
            setItems((prevItems) => prevItems.filter((item) => item.id !== itemToDelete));
            setItemToDelete(null);
        } catch (error) {
            console.error("Error deleting item: ", error);
        }
    };

    const handleNextPage = () => {
        if (hasMore) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setLastVisible(null); // Reset lastVisible to fetch the first page again
        }
    };

    const filteredItems = items.filter(
        (item) =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );



    const addToWB = async () => {
        const docId = localStorage.getItem("docId");
        if (!docId) {
            console.error("Document ID not found.");
            setIsLoading(false);
            return;
        }
        const vendorRef = doc(db, `Vendors`, docId);
        try {
            await updateDoc(vendorRef, { isItemToWB: true });
            console.log("Vendor details updated successfully.");
        } catch (error) {
            console.error("Error updating vendor details: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="main-panel container-fluid">
            <div className="content">
                <div className="container-fluid mt-3 p-3">
                    <h4 className="page-title mb-4">Item Inventory</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-tasks">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <div className="flex justify-between items-center mb-4">
                                        <input
                                            type="text"
                                            placeholder="Search here..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="border border-gray-300 rounded-lg px-4 py-2 w-1/3 focus:outline-none focus:ring focus:border-blue-500"
                                        />
                                    </div>
                                    <div className="d-flex gap-2">
                                        <button onClick={() => navigate('/inventory/add-item')} className="btn btn-primary btn-sm">
                                            Add New Item
                                        </button>
                                        <button onClick={addToWB} className="btn btn-primary btn-sm" disabled={isLoading}>
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                'Update to WB'
                                            )}
                                        </button>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive px-5 py-4 table-striped">
                                        {isLoading ? (
                                            <div className="loading-wave">
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                        ) : (
                                            <table className="table table-bordered table-hover ">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Title</th>
                                                        <th>Availability</th>
                                                        <th>Brand</th>
                                                        <th>Category</th>
                                                        <th>Sub Catalog</th>
                                                        <th>Description</th>
                                                        <th>Image Link</th>
                                                        <th>Price</th>
                                                        <th>Condition</th>
                                                        <th>Link</th>
                                                        <th>Item Group ID</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredItems.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td className="py-2 px-4">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                            <td>{item.title}</td>
                                                            <td>{item.availability}</td>
                                                            <td>{item.category}</td>
                                                            <td>{item.brand}</td>
                                                            <td>{item.sub_catalog}</td>
                                                            <td>{item.description}</td>
                                                            <td>{item.image_link}</td>
                                                            <td>{item.price}</td>
                                                            <td>{item.condition}</td>
                                                            <td>{item.link}</td>
                                                            <td>{item.item_group_id}</td>
                                                            <td className="td-actions">
                                                                <div className="form-button-action">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-link btn-success"
                                                                        title="Edit Item"
                                                                        onClick={() => navigate(`/inventory/update-item/${item.id}`)}
                                                                    >
                                                                        <i className="fas fa-edit" aria-hidden="true"></i>
                                                                        <span className="sr-only">Edit</span>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => handleDeleteButton(item.id)}
                                                                        className="btn btn-link btn-danger"
                                                                        title="Delete Item"
                                                                    >
                                                                        <i className="fas fa-trash-alt" aria-hidden="true"></i>
                                                                        <span className="sr-only">Delete</span>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}

                                        <div className="d-flex mt-4 justify-content-end align-items-center gap-2">
                                            <button
                                                disabled={currentPage === 1}
                                                onClick={handlePreviousPage}
                                                className={`btn ${currentPage === 1 ? "btn-secondary disabled" : "btn-primary"}`}
                                            >
                                                Previous
                                            </button>
                                            <span className="mx-2">
                                                Page {currentPage}
                                            </span>
                                            <button
                                                onClick={handleNextPage}
                                                className={`btn ${!hasMore ? "btn-secondary disabled" : "btn-primary"}`}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {itemToDelete && (
                <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setItemToDelete(null)}
                                    style={{ background: "none", border: "none", fontSize: "1.5rem", color: "#000" }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete this item?
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setItemToDelete(null)}
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-danger" onClick={handleDeleteItem}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
