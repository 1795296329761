import { s3Config } from "../s3Config";
import {
  S3Client,
  PutObjectCommand,
} from "@aws-sdk/client-s3";

const BUCKET_NAME = s3Config.bucketName;
const REGION = s3Config.region;
const ACCESS_KEY_ID = s3Config.accessKeyId;
const SECRET_ACCESS_KEY = s3Config.secretAccessKey;

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
});

export const uploadFile = async (file: File): Promise<string | null> => {
  try {
    const extension = file.name.split(".").pop();
    const fileName = `${crypto.randomUUID()}.${extension}`;
    const putCommand = new PutObjectCommand({
      Bucket: BUCKET_NAME,
      Key: fileName,
      Body: file,
    });

    await s3Client.send(putCommand);
    console.log("File uploaded successfully.", fileName);
    return buildFileUrl(fileName);
  } catch (error: any) {
    console.log("Error uploading file: ", error);
    return null;
  }
};

export const buildFileUrl = (fileName: string) => {
  return `https://${BUCKET_NAME}.s3-${REGION}.amazonaws.com/${fileName}`;
};
