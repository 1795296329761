import { useEffect, useState } from "react";
import { useRedux } from "./useRedux";
import { createSelector } from "reselect";
import { Chat, FirestoreChat } from "../types/Chat";
import { useAuthUser } from "./UserHooks";
import { db } from "../firebaseConfig";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { RootState } from "../redux/store";
import {
  changeSelectedChat,
  setIsFetchingChats,
  setChatList,
} from "../redux/actions";
import { toast } from "react-toastify";

// TODO: export hook via index.ts
export default function useChat(
  {
    listenChanges,
  }: {
    listenChanges?: boolean;
  } = { listenChanges: false },
) {
  const { useAppSelector, dispatch } = useRedux();
  const { user_id } = useAuthUser();

  const chatStoreSelector = createSelector(
    (state: RootState) => state.Chats,
    chats => ({
      selectedChat: chats.selectedChat,
      chatList: chats.chatList,
      isFetchingChats: chats.isFetchingChats,
    }),
  );

  const {
    selectedChat,
    chatList,
    isFetchingChats,
  }: { selectedChat: Chat | null; chatList: Chat[]; isFetchingChats: boolean } =
    useAppSelector(chatStoreSelector);

  // realtime listener to chats list
  useEffect(() => {
    if (!user_id || !listenChanges) return;
    const messagesRef = collection(db, "Vendors", user_id, "Chats");
    const q = query(messagesRef);

    dispatch(setIsFetchingChats(true));

    const unsubscribe = onSnapshot(q, snapshot => {
      const chatsData: Chat[] = snapshot.docs.map((document): Chat => {
        const chat = document.data() as FirestoreChat;
        chat.id = document.id;

        switch (chat.product) {
          case "whatsapp":
            return {
              ...chat,
              mobileNumber: chat.WAPhone ?? "",
              fullName: chat.customName ?? chat.whatsappName ?? chat.WAPhone,
            };
          case "instagram":
            return {
              ...chat,
              mobileNumber: "",
              fullName: chat.customName ?? "",
            };
          case "facebook":
            return {
              ...chat,
              mobileNumber: "",
              fullName: chat.customName ?? "",
            };
          default:
            let chatCopy = chat as any;
            return {
              ...chatCopy,
              product: "whatsapp",
              mobileNumber: chatCopy.WAPhone,
              fullName:
                chatCopy.customName ??
                chatCopy.whatsappName ??
                chatCopy.WAPhone,
            };
        }
      });
      console.log("chats length is " + chatsData.length);
      console.log("chats are ", chatsData);
      dispatch(setChatList(chatsData));
      dispatch(setIsFetchingChats(false));
    });

    // Cleanup function to unsubscribe from snapshot listener
    return () => unsubscribe();
  }, [user_id, listenChanges]);

  const selectChat = (chat_id: string) => {
    const selectedChat = chatList.find(chat => chat.id === chat_id);
    dispatch(changeSelectedChat(selectedChat ?? null));
  };

  const updateChat = async (chat: Partial<Chat>, chat_id?: string) => {
    if (!chat_id && selectedChat) chat_id = selectedChat.id;

    if (!user_id) {
      toast.error("User id not found");
      return;
    }

    if (!chat_id) {
      toast.error("Chat id not found");
      return;
    }

    const userDocRef = doc(db, "Vendors", user_id);
    const chatRef = doc(userDocRef, "Chats", chat_id);

    await updateDoc(chatRef, chat);
  };

  return {
    isFetchingChats,
    chatList,
    selectedChat,
    selectedChatId: selectedChat?.id ?? null,
    selectChat,
    updateChat,
  };
}
