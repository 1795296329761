import React from "react";
import { GoPlus } from "react-icons/go";
import "../../assets/scss/custom/pages/_product_card.scss";
import { BsThreeDots } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { useCart } from "./contexts/CartContext";

const Product = ({
  product,
  setCart,
  toast,
  setIsOpen,
  setCurrentModalData,
  sellerData,
}: {
  product: any;
  setCart: any;
  toast: any;
  setIsOpen: any;
  setCurrentModalData: any;
  sellerData: any;
}) => {
  const { addProductToCart } = useCart();

  const updateProductCart = async (product: any) => {
    const res = await addProductToCart(product);
    if (res === true) {
      setCart((prev: any) => {
        return [...prev, product];
      });
    }
  };
  return (
    <>
      <div className="product-outer">
        <div className="product-card">
          <div
            className="quickview-trigger"
            onClick={() => {
              setIsOpen(true);
              setCurrentModalData(product);
            }}
          >
            <BsThreeDots />
          </div>
          <div className="product-image">
            <img
              src={product?.image_link || ""}
              alt={product?.title}
              //   width={127}
              //   height={127}
            />
          </div>
          <div className="product-info">
            <h3>{product?.title}</h3>
          </div>
          <div className="product-actions">
            <div className="left">
              <h4 className="price">
                {product.price} <span>{product?.new_price}</span>
              </h4>
              {/* <h5>
                {sellerData?.currency || "BHD"}
               </h5> */}
            </div>

            <div className="right">
              <div
                className="cart"
                onClick={(e) => {
                  e.stopPropagation();
                  //   console.log("product ", product);
                  const updatedProduct = {
                    ...product,
                    quantity: 1,
                  };
                  updateProductCart(updatedProduct);
                  // toast.success(`${product.name} added to cart`);
                }}
              >
                <FiShoppingCart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
