import React, { createContext, useContext, useState, ReactNode } from "react";
import { doc, updateDoc, getDoc, collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../../../firebaseConfig";

interface CartContextProps {
  addProductToCart: (product: any) => Promise<boolean>;
  removeProductFromCart: (productId: string) => Promise<boolean>;
  updateProductQuantity: (
    productId: string,
    quantity: number
  ) => Promise<boolean>;
  isLoading: boolean;
  vendorId: string;
  chatId: string;
  cartId: string;
  setVendorId: (vendorId: string) => void;
  setChatId: (chatId: string) => void;
  setCartId: (cartId: string) => void;
  carts: any[];
  setCartProducts: (products: any[]) => void;
  removeOneProductFromCart: (productId: string) => Promise<boolean>;
  addOneProductToCart: (productId: string) => Promise<boolean>;
  WAPhone: string;
  setWAPhone: (phone: string) => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setLoading] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [chatId, setChatId] = useState("");
  const [cartId, setCartId] = useState("");
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [WAPhone, setWAPhone] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [waLoading, setWALoading] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const fetchCurrentCartProducts = async () => {
    if (!vendorId || !chatId || !cartId) return [];
    const cartDocPath = `/Vendors/${vendorId}/Chats/${chatId}/Carts/${cartId}`;
    const cartDocRef = doc(db, cartDocPath);
    const cartDoc = await getDoc(cartDocRef);
    setCartItems(cartDoc.data()?.products || []);
    return cartDoc.exists() ? cartDoc.data()?.products || [] : [];
  };

  const updateCartProducts = async (products: any[]) => {
    const cartDocPath = `/Vendors/${vendorId}/Chats/${chatId}/Carts/${cartId}`;
    const cartDocRef = doc(db, cartDocPath);
    await updateDoc(cartDocRef, { products });
  };

  const addProductToCart = async (product: any): Promise<boolean> => {
    if (!vendorId || !chatId || !cartId) {
      setIsOpen(true);
      return false;
    }
    try {
      setLoading(true);
      let currentProducts = await fetchCurrentCartProducts();

      const existingProductIndex = currentProducts.findIndex(
        (item: any) => item.id === product.id
      );

      if (existingProductIndex > -1) {
        currentProducts[existingProductIndex].quantity += product.quantity;
      } else {
        currentProducts.push({ ...product, quantity: product.quantity || 1 });
      }

      await updateCartProducts(currentProducts);
      fetchCurrentCartProducts();
      toast.success("Product added to cart successfully!");
      setLoading(false);
      return true;
    } catch (error) {
      console.error("Error adding product to cart:", error);
      toast.error("Error adding product to cart. Please try again.");
      setLoading(false);
      return false;
    }
  };

  const removeProductFromCart = async (productId: string): Promise<boolean> => {
    if (!vendorId || !chatId || !cartId) {
      setIsOpen(true);
      return false;
    }
    try {
      setLoading(true);
      let currentProducts = await fetchCurrentCartProducts();

      currentProducts = currentProducts.filter(
        (item: any) => item.id !== productId
      );

      await updateCartProducts(currentProducts);
      toast.success("Product removed from cart successfully!");
      setLoading(false);
      fetchCurrentCartProducts();
      return true;
    } catch (error) {
      console.error("Error removing product from cart:", error);
      toast.error("Error removing product from cart. Please try again.");
      setLoading(false);
      return false;
    }
  };

  const updateProductQuantity = async (
    productId: string,
    quantity: number
  ): Promise<boolean> => {
    try {
      setLoading(true);
      let currentProducts = await fetchCurrentCartProducts();

      const productIndex = currentProducts.findIndex(
        (item: any) => item.id === productId
      );

      if (productIndex > -1) {
        currentProducts[productIndex].quantity = quantity;
      } else {
        toast.error("Product not found in cart.");
        setLoading(false);
        return false;
      }

      await updateCartProducts(currentProducts);
      toast.success("Product quantity updated successfully!");
      setLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating product quantity:", error);
      toast.error("Error updating product quantity. Please try again.");
      setLoading(false);
      return false;
    }
  };

  const removeOneProductFromCart = async (
    productId: string
  ): Promise<boolean> => {
    try {
      setLoading(true);
      let currentProducts = await fetchCurrentCartProducts();

      const productIndex = currentProducts.findIndex(
        (item: any) => item.id === productId
      );

      if (productIndex > -1) {
        currentProducts[productIndex].quantity -= 1;
        if (currentProducts[productIndex].quantity <= 0) {
          currentProducts = currentProducts.filter(
            (item: any) => item.id !== productId
          );
        }
      } else {
        toast.error("Product not found in cart.");
        setLoading(false);
        return false;
      }

      await updateCartProducts(currentProducts);
      await fetchCurrentCartProducts();
      toast.success("Product quantity updated successfully!");
      setLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating product quantity:", error);
      toast.error("Error updating product quantity. Please try again.");
      setLoading(false);
      return false;
    }
  };

  const addOneProductToCart = async (productId: string): Promise<boolean> => {
    if (!vendorId || !chatId || !cartId) {
      setIsOpen(true);
      return false;
    }
    try {
      setLoading(true);
      let currentProducts = await fetchCurrentCartProducts();

      const productIndex = currentProducts.findIndex(
        (item: any) => item.id === productId
      );

      if (productIndex > -1) {
        currentProducts[productIndex].quantity += 1;
      } else {
        toast.error("Product not found in cart.");
        setLoading(false);
        return false;
      }

      await updateCartProducts(currentProducts);
      await fetchCurrentCartProducts();
      toast.success("Product quantity updated successfully!");
      setLoading(false);
      return true;
    } catch (error) {
      console.error("Error updating product quantity:", error);
      toast.error("Error updating product quantity. Please try again.");
      setLoading(false);
      return false;
    }
  };
  // console.log("vendorId", vendorId);
  const createChatWithCart = async () => {
    setWALoading(true);
    try {
      // Reference to the Chats collection under the vendor
      const chatsCollectionRef = collection(db, `Vendors/${vendorId}/Chats`);
      // console.log("chatsCollectionRef", chatsCollectionRef);

      // Add a new chat and get its ID
      const chatDocRef = await addDoc(chatsCollectionRef, {
        WAPhone:WAPhone,
        createdAt: new Date(),
      });
      // console.log("chatDocRef", chatDocRef);
      const chatId = chatDocRef.id; // Auto-generated chat ID
      // console.log("chatId", chatId);

      // Reference to the Carts collection under the chat
      const cartsCollectionRef = collection(chatDocRef, "Carts");
      // console.log("cartsCollectionRef", cartsCollectionRef);

      // Add a new cart with the provided data
      const cartDocRef = await addDoc(cartsCollectionRef, {
        createdAt: new Date(),
      });

      const cartId = cartDocRef.id; // Auto-generated cart ID

      // add cartId in cart
      await updateDoc(cartDocRef, { cartId });

      // console.log("cartDocRef", cartDocRef.path);

      // console.log("Chat created with ID:", chatId);
      // console.log("Cart created with ID:", cartId);
      setChatId(chatId);
      setCartId(cartId);
      onClose();
    } catch (error) {
      console.error("Error creating chat with cart:", error);
    } finally{
      setWALoading(false);
    }
  };

  return (
    <CartContext.Provider
      value={{
        addProductToCart,
        removeProductFromCart,
        updateProductQuantity,
        isLoading,
        vendorId,
        chatId,
        cartId,
        setVendorId,
        setChatId,
        setCartId,
        carts: cartItems,
        setCartProducts: setCartItems,
        removeOneProductFromCart,
        addOneProductToCart,
        WAPhone,
        setWAPhone,
      }}
    >
      {children}
      <UserDetailModal
        isOpen={isOpen}
        onClose={onClose}
        setWAPhone={setWAPhone}
        WAPhone={WAPhone}
        createChatWithCart={createChatWithCart}
        waLoading={waLoading}

      />
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

const UserDetailModal = ({
  isOpen,
  onClose,
  WAPhone,
  setWAPhone,
  createChatWithCart,
  waLoading
}: {
  isOpen: boolean;
  onClose: () => void;
  WAPhone: string;
  setWAPhone: (phone: string) => void;
  createChatWithCart: () => void;
  waLoading: boolean;
}) => {
  return (
    <div>
      <div className={`modal checkout-modal ${isOpen ? "is-active" : ""}`}>
        <div className="modal-background" onClick={onClose}></div>
        <div className="modal-content">
          <div className="user-detail-modal">
            <label htmlFor="phone">Enter Your Phone Number</label>
            <input
              type="text"
              id="phone"
              value={WAPhone}
              onChange={(e) => setWAPhone(e.target.value)}
              placeholder="Enter your phone number"
            />
            <button
              className="button is-primary"
              disabled={!WAPhone || waLoading}
              onClick={createChatWithCart}
            >
              {waLoading ? "Creating chat..." : "Create"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
