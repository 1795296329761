import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: { 
        navbar: {
          brandLogo: "Logo",
          deliveryTo: "Delivery to:",
          selectAddress: "Select Address",
          searchPlaceholder: "Search for products...",
          myCart: "My Cart",
          register: "Register",
          languageEnglish: "English",
          languageArabic: "عربى",
        },
        cart: {
          itemRemoved: "Item removed from cart",
        },
        addressModal: {
          manageLocations: "Manage Locations",
          close: "Close",
          loading: "Loading...",
          allowLocation: "Allow Location",
          allowLocationNotice:
            "Allow location access to manage and save your locations.",
          withoutLocationAccess: "Without allowing location:",
          cannotSaveLocations: "You can't save locations",
          cannotUseLocationServices: "You can't use location-based services",
          enterName: "Enter name",
          enterAddress: "Enter your address",
          addLocation: "Add Location",
          updateLocation: "Update Location",
          cancelEdit: "Cancel Edit",
          savedLocations: "Saved Locations",
          noLocationsFound: "No saved locations found.",
          edit: "Edit",
          delete: "Delete",
          locationUpdatedSuccess: "Location updated successfully!",
          locationAddedSuccess: "Location added successfully!",
          locationDeletedSuccess: "Location deleted successfully!",
          locationFetchError: "Failed to fetch locations.",
          locationSaveError: "Failed to save location.",
          locationDeleteError: "Failed to delete location.",
        },
        checkout: {
          confirmOrder: "Confirm Your Order",
          shipping: "Shipping",
          payment: "Payment",
          thankYou: "Thank You",
          product: "Product",
          quantity: "Quantity",
          price: "Price",
          total: "Total",
          subtotal: "Subtotal",
          shippingCost: "Shipping",
          taxes: "Taxes",
          discount: "Discount",
          orderSummary: "Order Summary",
          transactionId: "Transaction ID",
          paymentSuccess: "Your payment was successful.",
          paymentThankYou: "Thank you for your purchase! We will prepare your order and ship it soon.",
          paymentFailure: "Your payment was not successful.",
          paymentErrorMessage: "We encountered an issue processing your payment. Please try again or contact support.",
          retryPayment: "Retry Payment",
          errorDetails: "If the issue persists, please contact our customer support team for assistance.",
          orderNotProcessed: "Your order has not been processed due to payment failure.",
          continue: "Continue",
          country: "Country",
          confirmPayment: "Pay",
          addAddress: "Add Address",
          shippingAddress: "Shipping Address",
          selectShipping: "Please select a shipping address.",
          paymentInformation: "Payment Information",
          totalAmount: "Total Amount",
          shippingInProgress: "Shipping..."
        }
        ,
        about: {
          overview: "Overview",
          categories: "Categories",
          products: "Products",
          aboutUs: "About Us",
          logoPlaceholder: "Logo",
        },
        cartModal: {
          cart: "Cart",
          yourCartIsEmpty: "Your cart is empty",
          product: "Product",
          quantity: "Quantity",
          price: "Price",
          subtotal: "Subtotal",
          total: "Total",
          orderSummary: "Order Summary",
          checkout: "Checkout",
          removeItem: "Remove item",
          addItem: "Add item",
        },
        categoryDetail: {
          title: "Category Details",
          activeCategory: "Active Category",
          subcategories: "Subcategories",
          products: "Products",
          addToCart: "Add To Cart",
          productPrice: "{price} BHD",
          productAdded: "{name} added to cart",
          productDescription:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ubi ut eam caperet aut quando?",
        },
      },
    },
    Arabic: {
      translation: {
        navbar: {
          brandLogo: "شعار العلامة التجارية",
          deliveryTo: "التوصيل إلى:",
          selectAddress: "حدد العنوان",
          searchPlaceholder: "ابحث عن المنتجات...",
          myCart: "سلتي",
          register: "سجل",
          languageEnglish: "English",
          languageArabic: "عربى",
        },
        cart: {
          itemRemoved: "تمت إزالة العنصر من السلة",
        },
        addressModal: {
          manageLocations: "إدارة المواقع",
          close: "إغلاق",
          loading: "جاري التحميل...",
          allowLocation: "السماح بالموقع",
          allowLocationNotice: "اسمح بالوصول إلى الموقع لإدارة المواقع وحفظها.",
          withoutLocationAccess: "بدون السماح بالوصول إلى الموقع:",
          cannotSaveLocations: "لا يمكنك حفظ المواقع",
          cannotUseLocationServices: "لا يمكنك استخدام خدمات الموقع",
          enterName: "أدخل الاسم",
          enterAddress: "أدخل العنوان",
          addLocation: "إضافة موقع",
          updateLocation: "تحديث الموقع",
          cancelEdit: "إلغاء التعديل",
          savedLocations: "المواقع المحفوظة",
          noLocationsFound: "لم يتم العثور على مواقع محفوظة.",
          edit: "تعديل",
          delete: "حذف",
          locationUpdatedSuccess: "تم تحديث الموقع بنجاح!",
          locationAddedSuccess: "تمت إضافة الموقع بنجاح!",
          locationDeletedSuccess: "تم حذف الموقع بنجاح!",
          locationFetchError: "فشل في جلب المواقع.",
          locationSaveError: "فشل في حفظ الموقع.",
          locationDeleteError: "فشل في حذف الموقع.",
        },
        checkout: {
          confirmOrder: "تأكيد الطلب",
          shipping: "الشحن",
          payment: "الدفع",
          thankYou: "شكرا لك",
          product: "المنتج",
          quantity: "الكمية",
          price: "السعر",
          total: "المجموع",
          subtotal: "الإجمالي الفرعي",
          shippingCost: "الشحن",
          taxes: "الضرائب",
          discount: "الخصم",
          orderSummary: "ملخص الطلب",
          transactionId: "رقم المعاملة",
          paymentSuccess: "تم الدفع بنجاح.",
          paymentThankYou:
            "شكرا لك على الشراء! سنقوم بتحضير طلبك وشحنه قريبًا.",
          continue: "استمرار",
          country: "الدولة",
          confirmPayment: "تأكيد الدفع",
          addAddress: "إضافة عنوان",
          shippingAddress: "عنوان الشحن",
          selectShipping: "يرجى اختيار عنوان الشحن.",
          paymentInformation: "معلومات الدفع",
          totalAmount: "المبلغ الإجمالي",
          shippingInProgress: "جاري الشحن...",
        },
        about: {
          overview: "نظرة عامة",
          categories: "الفئات",
          products: "المنتجات",
          aboutUs: "معلومات عنا",
          logoPlaceholder: "الشعار",
        },
        cartModal: {
          cart: "عربة التسوق",
          yourCartIsEmpty: "عربة التسوق الخاصة بك فارغة",
          product: "المنتج",
          quantity: "الكمية",
          price: "السعر",
          subtotal: "الإجمالي الفرعي",
          total: "المجموع",
          orderSummary: "ملخص الطلب",
          checkout: "الدفع",
          removeItem: "إزالة العنصر",
          addItem: "إضافة عنصر",
        },
        categoryDetail: {
          title: "تفاصيل الفئة",
          activeCategory: "الفئة النشطة",
          subcategories: "الفئات الفرعية",
          products: "المنتجات",
          addToCart: "أضف إلى السلة",
          productPrice: "{price} دينار بحريني",
          productAdded: "تمت إضافة {name} إلى السلة",
          productDescription:
            "نص افتراضي للعرض السريع على المنتج. يشمل مواصفات مختصرة.",
        },
      },
    },
  },

  lng: localStorage.getItem("appLanguage") || "English", // Default language
  fallbackLng: "English",
  interpolation: { escapeValue: false },
});

export default i18n;
