import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TapCard,
  Currencies,
  Direction,
  Edges,
  Locale,
  Theme,
  tokenize,
} from "@tap-payments/card-sdk";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom";


const PaymentSection = ({
  total,
  vendorId,
  chatId,
  cartId,
  cart,
  WAPhone,
  shippingAddress,
  setCurrentStep,
  onPaymentFailure,
}: {
  total: number;
  vendorId: string;
  chatId: string;
  cartId: string;
  cart: any;
  WAPhone: string;
  shippingAddress: any;
  setCurrentStep: any;
  onPaymentFailure: any;
}) => {
  const { t } = useTranslation();
  const handlePayment = async () => {
    tokenize();
    setIsPaymentProcessing(true);
  };

  const [nameOnCard, setNameOnCard] = useState<string>("");
  const param = useParams();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [isPaymentProcessing, setIsPaymentProcessing] =
    useState<boolean>(false);
  
  const [storeId, setStoreId] = useState<string>("");
  useEffect(() => {
    const sid = param?.storeId?.toLocaleLowerCase();
    console.log(sid, " sid");
    if (sid) {
      setStoreId(sid);
    }
  }, [param]);

  const req = {
    pk: "pk_test_EtHFV4BuPQokJT6jiROls87Y",
    currency: "BHD",
    locale: "en",
    direction: "ltr",
    edges: "curved",
    theme: "light",
    loader: true,
    saveCard: true,
    cardHolder: true,
    cardName: nameOnCard,
    displayPaymentBrands: true,
    customerId: "",
    cardId: "",
    amount: total,
    orderId: "",
    transactionReference: "tck_LV02G1720231634Xj02430089",
    orderReference: "",
    invoiceId: "",
    supportedBrands: "MADA,VISA,MASTERCARD,AMEX,AMERICAN_EXPRESS",
    mid: "599424",
    scope: "Token",
    colorStyle: "colored",
    autoSaveCard: true,
    purpose: "Charge",
    email: "",
    phoneNumber: WAPhone,
    countryCode: "+965",
    contractId: "",
    cardCVV: true,
    savedCardCVV: true,
  };

  // Success handling function
  const onSuccess = async (
    data: any,
    vendorId: string,
    chatId: string,
    cartId: string,
    cart: any,
    WAPhone: string,
    address: string,
    paidAmount: any
  ) => {
    console.log("onSuccess", data);

    createCharge(data.id, paidAmount, address);
  };
  

  const createCharge = async (id: string, amount: number, address: string) => {
    if (!id || !amount) return;
    const description = `Charge for order from ${address} for ${amount} BHD at ${new Date().toLocaleString()} and cartId: ${cartId} and chatId: ${chatId}`;
    try {
      const response = await axios.post(
        "https://us-central1-tryorderbotprod.cloudfunctions.net/createCharge",
        {
          id,
          amount,
          name: nameOnCard,
          description,
          currency: "BHD",
           storeId,
           number:WAPhone
        }
      );
      // console.log(response.data,"response from charge");

      if (response?.data?.response?.message === "DECLINED") {
        toast.error("Payment declined. Please try again.");
        setIsPaymentProcessing(false);
        // setCurrentStep(4);
        onPaymentFailure();
      } else if (response?.data?.response?.message === "Captured") {
        // getChargeDetails(response.data.id); //
        toast.success("Payment successful.");

        try {
          const cartDocRef = doc(
            db,
            `Vendors/${vendorId}/Chats/${chatId}/Carts`,
            cartId
          );
          await updateDoc(cartDocRef, {
            payment: {
              status: "ordered",
              type: "card",
              paidAmount: amount,
              request: req,
              response: response?.data,
            },
            shippingAddress: address,
            status: "paid",
          });
          console.log("Cart updated with paid status.");

          // Create order data
          const orderData = {
            vendorId,
            chatId,
            cart,
            WAPhone,
            status: "pending",
            shippingAddress: address,
            total: amount,
            createdAt: new Date(),
          };

          const ordersCollectionRef = collection(
            db,
            `Vendors/${vendorId}/Orders`
          );
          await addDoc(ordersCollectionRef, orderData);
          console.log("Order document created.");

          // Create transaction data
          const transactionData = {
            vendorId,
            chatId,
            data: response?.data,
            WAPhone,
            status: "paid",
            amount: amount,
            createdAt: new Date(),
          };

          const transactionsCollectionRef = collection(
            db,
            `Vendors/${vendorId}/Transactions`
          );

          await addDoc(transactionsCollectionRef, transactionData);

          // await addTransaction(data);
          console.log("Transaction document created.");
          toast.success(`${t("checkout.paymentSuccess")}`);
          setCurrentStep(5);
        } catch (error) {
          console.error("Error processing success flow:", error);
        }
      } else {
        toast.error("Error processing payment. Please try again.");
        setIsPaymentProcessing(false);
        onPaymentFailure();
        // setCurrentStep(4);
        // setCurrentStep(6);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error processing payment");
      setIsPaymentProcessing(false);
      // setCurrentStep(3);
      onPaymentFailure();
    }
  };

  const getChargeDetails = async (id: string) => {
    console.log(id, "id");
    if (!id) return;
    try {
      const response = await axios.get(
        `https://us-central1-tryorderbotprod.cloudfunctions.net/getCharge?chargeId=chg_TS05A2320251151Mu5e0301214`
      );
      console.log(response.data, "response from charge");
    } catch (error) {
      console.log(error);
    }
  };

  const [typingTimeout, setTypingTimeout] = useState(null);
  const [showSecondInput, setShowSecondInput] = useState(false);

  const handleFirstInputChange = (e:any) => {
    const value = e.target.value;
    setNameOnCard(value);

    // Clear previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to show the second input after a delay
    const timeout = setTimeout(() => {
      if (value.trim() !== '') {
        setShowSecondInput(true);
      } else {
        setShowSecondInput(false);
      }
    }, 1700); // Adjust delay time as needed

    // @ts-ignore
    setTypingTimeout(timeout);
  };

  return (
    <>
      <div className="checkout-container">
        <div className="left">
          <div className="left-header">
            <div className="header-inner">
              <div className="title-wrap"></div>
            </div>
            <div className="header-foot">
              <div className="total-amount">
                <span className="label">{t("checkout.totalAmount")}</span>
                <span className="value">{total.toFixed(2)} BHD</span>
              </div>
            </div>
          </div>

          <div className="payment-form">
            <form className="provider-form is-active">
              <div className="form-section">
                <h3>{t("checkout.paymentInformation")}</h3>

                {/* <label>
                  Name on Card <span className="required">*</span>
                </label> */}
                <input
                  type="text"
                  className="input name-card"
                  value={nameOnCard}
                  placeholder="Enter name on card"
                  onChange={handleFirstInputChange}
                  // disabled={!isValid}
                />

                {
                  showSecondInput && (
                    <>
                    <TapCard
                  publicKey="pk_test_EtHFV4BuPQokJT6jiROls87Y"
                  merchant={{
                    id: "599424",
                  }}
                  transaction={{
                    amount: total,
                    currency: Currencies.SAR,
                  }}
                  customer={{
                    id: "",
                    name: [
                      {
                        lang: Locale.EN,
                        first: nameOnCard,
                        last: "",
                        middle: "",
                      },
                    ],
                    nameOnCard: nameOnCard,
                    editable: false,
                    contact: {
                      phone: {
                        countryCode: "965",
                        number: WAPhone,
                      },
                    },
                  }}
                  acceptance={{
                    supportedBrands: [
                      "AMEX",
                      "VISA",
                      "MASTERCARD",
                      "MADA",
                      "AMERICAN_EXPRESS",
                    ],
                    supportedCards: ["ALL"],
                  }}
                  fields={{
                    cardHolder: true,
                  }}
                  addons={{
                    displayPaymentBrands: true,
                    loader: true,
                    saveCard: false,
                  }}
                  interface={{
                    locale: Locale.EN,
                    theme: Theme.LIGHT,
                    edges: Edges.CURVED,
                    direction: Direction.LTR,
                  }}
                  onReady={() => console.log("onReady")}
                  onFocus={() => console.log("onFocus")}
                  onBinIdentification={(data) => {
                    console.log("onBinIdentification", data);
                    if (data?.bin) {
                      setIsValid(true);
                    }
                  }}
                  onValidInput={(data) => {
                    // console.log("onValidInputChange", data)
                  }}
                  onCompleteTyping={(data) => {
                    console.log("onCompleteTyping", data);
                    if (data) {
                      setIsValid(data as boolean);
                    }
                  }}
                  onInvalidInput={(data) => {
                    // console.log("onInvalidInput", data)
                  }}
                  onError={(data) => {
                    console.log("onError", data);

                    onError(data, vendorId, chatId, cartId).then(() => {
                      setCurrentStep(6);
                    });
                  }}
                  onSuccess={(data) => {
                    console.log("onSuccess", data);
                    onSuccess(
                      data,
                      vendorId,
                      chatId,
                      cartId,
                      cart,
                      WAPhone,
                      shippingAddress,
                      total
                    );
                  }}
                />
                    </>
                  )
                }

                {/* <label>Country </label>
                <select
                  className="select"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  disabled={!isValid}
                >
                  <option value="Bahrain">Bahrain</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Qatar">Qatar</option>
                </select> */}
              </div>

              <button
                type="button"
                className="button confirm-btn is-solid primary-button raised"
                onClick={handlePayment}
                disabled={!isValid || isPaymentProcessing}
              >
                {/* {t("checkout.confirmPayment")} */}
                {isPaymentProcessing
                  ? "Processing..."
                  : `${t("checkout.confirmPayment")}`}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSection;

// Error handling function
const onError = async (
  data: any,
  vendorId: string,
  chatId: string,
  cartId: string
) => {
  console.log("onError", data);

  try {
    const cartDocRef = doc(
      db,
      `Vendors/${vendorId}/Chats/${chatId}/Carts`,
      cartId
    );
    await updateDoc(cartDocRef, {
      status: "failed",
      error: data,
    });
    console.log("Cart updated with failed status.");
  } catch (error) {
    console.error("Error updating cart with failed status:", error);
  }
};
