import { useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// interface
import { STATUS_TYPES } from "../../constants";
import { Chat } from "../../types/Chat";

interface ChatUserProps {
  user: Chat;
  selectedChat: string | number;
  onSelectChat: (id: string) => void;
}
const ChatUser = ({ user, selectedChat, onSelectChat }: ChatUserProps) => {
  const fullName = user.fullName;
  const shortName = user.fullName;
  const lastMessage = user.last_message;
  const tags = user.tags ?? [];

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  // if last message has been more less than a 24 hours, set isOnline to true, else set it to false
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const isOnline = user.last_message_time
    ? user.last_message_time.toDate() > yesterday
      ? true
      : false
    : false;
  const unRead = user.meta && user.meta.unRead;
  const product_type = user.product;

  const isSelectedChat: boolean =
    selectedChat && selectedChat === user.id ? true : false;

  const onClick = async () => {
    onSelectChat(user.id);
  };

  return (
    <li className={classnames({ active: isSelectedChat })} onClick={onClick}>
      <Link to="#" className={classnames({ "unread-msg-user": unRead })}>
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "chat-user-img",
              "align-self-center",
              "me-2",
              "ms-0",
              { online: isOnline },
            )}
          >
            {user.profileImage ? (
              <>
                <img
                  src={user.profileImage}
                  className="rounded-circle avatar-xs"
                  alt=""
                />
                {isOnline && <span className="user-status"></span>}
              </>
            ) : (
              <div className="avatar-xs">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    isSelectedChat ? "bg-light" : "avatar-background-light",
                  )}
                >
                  {user.modeEmoji ?? "🙂"}
                  <span className="user-status"></span>
                </span>
              </div>
            )}
          </div>
          <div className="overflow-hidden w-100 flex-grow-1">
            <div className="">
              <p className="text-truncate mb-0 fw-bold lh-1 d-block">
                {fullName}
              </p>
              <small className="text-truncate mb-0 d-block">
                {lastMessage}
              </small>
              <div className="d-flex flex-wrap gap-1">
                {tags.map((tag, index) => (
                  <div
                    className={`btn ${isSelectedChat ? "btn-light" : "btn-soft-primary"} btn-sm py-1 px-2`}
                    style={{
                      fontSize: "12px",
                      height: "fit-content",
                      lineHeight: ".9",
                    }}
                    key={index}
                  >
                    #{tag}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center ms-1">
            {product_type === "whatsapp" ? (
              <i className="bx bxl-whatsapp bx-sm" />
            ) : product_type === "facebook" ? (
              <i className="bx bxl-facebook-square bx-sm" />
            ) : ( //instagram
              <i className="bx bxl-instagram bx-sm" />
            )}
          </div>
          {unRead && unRead !== 0 ? (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {unRead}
              </span>
            </div>
          ) : null}
        </div>
      </Link>
    </li>
  );
};

export default ChatUser;
