import { FaTimes } from "react-icons/fa";
import { FiMinus } from "react-icons/fi";
import { IoIosAdd } from "react-icons/io";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useCart } from "./contexts/CartContext";


// interface CartItem {
//     id: number;
//     name: string;
//     quantity: number;
//     price: number;
//     image_link: string;
//   }
  
  interface CartModalProps {
    cartOpen: boolean;
    cartItems: any[];
    toggleCart: () => void;
    removeCartItem: (id: number) => void;
    checkout: () => void;
    addCartItem: (id: number) => void;
    sellerData: any;
    loading: boolean; 
  }
  
  const CartModal: React.FC<CartModalProps> = ({
    cartOpen,
    cartItems,
    toggleCart,
    removeCartItem,
    checkout,
    addCartItem,
    sellerData,
    loading,
  }) => {
    const {carts,addOneProductToCart,removeOneProductFromCart} = useCart();
    const subtotal = carts.reduce(
      // price 604.06 BHD
      (acc, item) => acc + item.quantity * Number(item?.price?.replace(" BHD", "")),
      0
    );
    console.log("subtotal",subtotal,carts)
    const tax = (subtotal * Number(sellerData?.vat)) / 100;
    // console.log("sellerData",sellerData?.vat," ",subtotal," ",tax)
    const total = subtotal + tax;
    const { t } = useTranslation();
  
    return (
      <div className={`cart-modal ${cartOpen ? "open" : ""}`}>
        <div className="cart-container">
          <div className="cart-header">
            <div className="header-inner">
              <h2> 
              {t("cartModal.cart")}
              </h2>
              <div className="header-actions">
                <Button color="danger" onClick={toggleCart}>
                  <FaTimes />
                </Button>
              </div>
            </div>
          </div>
  
          <div className="cart-content">
            <div className="flex-table">
             
              {carts.length > 0 ? (
                carts.map((item,index) => (
                  <div className="flex-table-item" key={index}>
                    <div className="product">
                      <img src={item?.image_link}
                       alt="product" />
                      <div className="price">
                      <span className="product-name">{item?.title ||""}</span>
                        <span className="price-t">{item?.price} </span>
                      </div>
                    </div>
  
                    <div className="quantity">
                      <div className="spinner">
                        <button
                          className="remove"
                          onClick={() => {
                            removeOneProductFromCart(item.id);
                          
                          }}
                        >
                          <FiMinus size={14} />
                        </button>
                        <span className="value">{item?.quantity}</span>
                        <button
                          className="add"
                          onClick={() => {
                            addOneProductToCart(item.id);
                            // addCartItem(item.id)}}
                          }}
                        >
                          <IoIosAdd size={14} />
                        </button>
                      </div>
                    </div>
  
                    {/* <div className="total">
                      <span>
                        {Number(item.quantity * item.price)?.toFixed(2)}
                      </span>
                    </div> */}
                  </div>
                ))
              ) : (
                <p className="empty-cart">
                      {t("cartModal.yourCartIsEmpty")}
                </p>
              )}
            </div>
  
            <div className="cart-summary">
              <div className="summary-header">
                <h3>
                {t("cartModal.orderSummary")}
                </h3>
              </div>
              <div className="summary-card">
                <div className="order-line">
                  <span>
                  {t("cartModal.subtotal")}
                  </span>
                  <span>{subtotal.toFixed(2)} </span>
                </div>
                {/* <div className="order-line">
                  <span>Vat</span>
                  <span>{tax.toFixed(2)} </span>
                </div> */}
                <div className="order-line">
                  <span className="is-total">
                  {t("cartModal.total")}
                  </span>
                  <span className="is-total">{total.toFixed(2)}</span>
                </div>
              </div>
              <Button
                color="primary"
                block
                onClick={checkout}
                className="checkout-btn"
                disabled={cartItems.length === 0}
              >
                 {t("cartModal.checkout")}
                
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  

  export default CartModal;