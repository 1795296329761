import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, orderBy, startAfter, limit, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import '../../assets/scss/custom/pages/page.css'

export interface Vendor {
    id?: string;
    fullName: string;
    email: string;
    mobileNumber: string;
    companyName: string;
}

export interface InventoryItem {
    Catalog: string;
    min1stAmount: number;
    firstPercentagePerOrder: string;
    min2ndAmount: number;
    secondPercentagePerOrder: string;
    min3rdAmount: number;
    thirdPercentagePerOrder: string;
    VAT: string
}

export interface Order {
    id?: string;
    customerName: string;
    orderDate: string; // Assuming date is in "YYYY-MM-DD" format
    status: string;
    totalAmount: number;
    externalFields: string; // New field for external JSON data
}

export interface Payment {
    createdAt: string;
    items: any[];
    phone: string;
    phone_number_id: string;
    totalAmount: number;
    response: string;
    status: string;
}

export default function Vendors() {
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const [lastVisible, setLastVisible] = useState<any>(null);
    const [hasMore, setHasMore] = useState(true);
    const [userRole, setUserRole] = useState(false); // Default to `null`
    const [vendorToDelete, setVendorToDelete] = useState<string | null>(null);
    const [inventory, setInventory] = useState<InventoryItem[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [summary, setSummary] = useState<any[]>([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVendors = async () => {
            const storedRole = localStorage.getItem("userRole");
            if (storedRole) {
                setUserRole(storedRole === 'Owner');
            }
            if (!userRole) return;

            try {
                const docId = localStorage.getItem("docId");

                if (!docId) {
                    console.error("Document ID not found in localStorage.");
                    return;
                }

                const vendorsCollection = collection(db, `Vendors`);
                let q = query(vendorsCollection, orderBy("fullName"), limit(itemsPerPage));

                if (lastVisible) {
                    q = query(vendorsCollection, orderBy("fullName"), startAfter(lastVisible), limit(itemsPerPage));
                }

                const querySnapshot = await getDocs(q);
                const vendorsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...(doc.data() as Omit<Vendor, "id">),
                }));

                if (vendorsData.length < itemsPerPage) {
                    setHasMore(false);
                }

                setVendors(vendorsData);
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            } catch (error) {
                console.error("Error fetching vendors: ", error);
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };

        fetchVendors();
    }, [userRole, currentPage]); // Add `userRole` to the dependency array

    useEffect(() => {
        const fetchInventory = async () => {
            const docId = localStorage.getItem("docId");
            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            const inventoryCollection = collection(db, `Vendors/${docId}/Inventory`);
            const inventorySnapshot = await getDocs(inventoryCollection);
            const inventoryData = inventorySnapshot.docs.map(doc => doc.data() as InventoryItem);
            setInventory(inventoryData);
        };

        fetchInventory();
    }, []);

    useEffect(() => {
        const fetchOrders = async () => {
            const docId = localStorage.getItem("docId");
            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            const ordersCollection = collection(db, `Vendors/${docId}/Orders`);
            const ordersSnapshot = await getDocs(ordersCollection);
            const ordersData = ordersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as Order));
            setOrders(ordersData);
        };

        fetchOrders();
    }, []);

    const handleDeleteVendor = async () => {
        if (!vendorToDelete) return;

        try {
            const docId = localStorage.getItem("docId");

            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            await deleteDoc(doc(db, `Vendors/${docId}/Vendors`, vendorToDelete));
            setVendors((prevVendors) =>
                prevVendors.filter((vendor) => vendor.id !== vendorToDelete)
            );
            setVendorToDelete(null);
        } catch (error) {
            console.error("Error deleting vendor:", error);
        }
    };

    const handleNextPage = () => {
        if (hasMore) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setLastVisible(null); // Reset lastVisible to fetch the first page again
        }
    };

    const filteredVendors = vendors.filter(
        (vendor) =>
            vendor.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            vendor.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            vendor.mobileNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
            vendor.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const checkAndAddCatalog = async (vendorId: string) => {
        const docId = localStorage.getItem("docId");
        if (!docId) {
            console.error("Document ID not found in localStorage.");
            return;
        }

        const inventoryCollection = collection(db, `Vendors/${docId}/Inventory`);
        const inventorySnapshot = await getDocs(inventoryCollection);
        const existingCatalogs = new Set(inventorySnapshot.docs.map(doc => doc.data().Catalog));

        const defaultCatalogs = [
            { catalog: "catalog1", min1stAmount: 0, firstPercentagePerOrder: "10%", min2ndAmount: 10000, secondPercentagePerOrder: "7%", min3rdAmount: 25000, thirdPercentagePerOrder: "5%" },
            // Add more default catalogs if needed
        ];

        for (const defaultCatalog of defaultCatalogs) {
            if (!existingCatalogs.has(defaultCatalog.catalog)) {
                await addDoc(inventoryCollection, defaultCatalog);
            }
        }
    };

    const handleAddItem = async (vendorId: string) => {
        await checkAndAddCatalog(vendorId);
        // Add your item addition logic here
    };




    return (
        <div className="main-panel container-fluid">
            <div className="content">
                <div className="container-fluid mt-3 p-3">
                    {
                        userRole ?
                            (
                                <>
                                    <h4 className="page-title mb-4">Vendors</h4>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card card-tasks">
                                                <div className="card-header d-flex justify-content-between align-items-center">
                                                    <div className="flex justify-between items-center mb-4">
                                                        <input
                                                            type="text"
                                                            placeholder="Search here..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                            className="border border-gray-300 rounded-lg px-4 py-2 w-1/3 focus:outline-none focus:ring focus:border-blue-500"
                                                        />
                                                    </div>
                                                    <div className="d-flex gap-2">
                                                        <button
                                                            className="btn btn-primary btn-sm"
                                                            onClick={() => navigate('/vendor/add-vendor')}
                                                        >
                                                            Add New Vendor
                                                        </button>

                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="table-responsive px-5 py-4 table-striped">
                                                        {loading ? (
                                                            <div className="loading-wave">
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                                <div className="loading-bar"></div>
                                                            </div>

                                                        ) : (
                                                            <table className="table table-bordered table-hover ">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Full Name</th>
                                                                        <th>Email</th>
                                                                        <th>Phone Number</th>
                                                                        <th>Company Name</th>
                                                                        <th>VAT Id</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {filteredVendors.map((vendor, index) => (
                                                                        <tr key={vendor.id}>
                                                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                                            <td>{vendor.fullName}</td>
                                                                            <td>{vendor.email}</td>
                                                                            <td>{vendor.mobileNumber}</td>
                                                                            <td>{vendor.companyName}</td>
                                                                            <td>1</td>
                                                                            <td className="td-actions">
                                                                                <button
                                                                                    className="btn btn-link btn-success"
                                                                                    title="Edit Vendor" // Tooltip for edit button
                                                                                    onClick={() => navigate(`/vendor/update-vendor/${vendor.id}`)}
                                                                                >
                                                                                    <i className="fas fa-edit"></i>
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-link btn-danger"
                                                                                    title="Delete Vendor" // Tooltip for delete button
                                                                                    onClick={() => setVendorToDelete(vendor.id!)}
                                                                                >
                                                                                    <i className="fas fa-trash-alt"></i>
                                                                                </button>
                                                                                {/* <button
                                                                                    className="btn btn-link btn-info"
                                                                                    title="View Vendor Details" // Tooltip for view details button
                                                                                    onClick={() => navigate(`/vendor/vendor-details/${vendor.id}`)}
                                                                                >
                                                                                    <i className="fas fa-eye"></i>
                                                                                </button> */}
                                                                            </td>

                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                        <div className="d-flex mt-4 justify-content-end align-items-center gap-2">
                                                            <button
                                                                disabled={currentPage === 1}
                                                                onClick={handlePreviousPage}
                                                                className={`btn ${currentPage === 1 ? "btn-secondary disabled" : "btn-primary"}`}
                                                            >
                                                                Previous
                                                            </button>
                                                            <span className="mx-2">
                                                                Page {currentPage}
                                                            </span>
                                                            <button
                                                                onClick={handleNextPage}
                                                                className={`btn ${!hasMore ? "btn-secondary disabled" : "btn-primary"}`}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>) : null}
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            {vendorToDelete && (
                <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="close" onClick={() => setVendorToDelete(null)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this vendor?</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setVendorToDelete(null)}
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-danger" onClick={handleDeleteVendor}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
